import React from 'react';
import _ from 'lodash'
import {Badge, CurrencySelect, uniqueShortName, TabBar} from "../common/common_components";
import {round} from "../common/common_components_util";
import {haxxEnabled, jsonTableStringify} from "../common/utils";
import {getStore} from '../common/redux_store'
import ModalController from "../common/Modal";
import {Link, withRouter} from "react-router-dom";
import {TableImageUpload} from "../bill_create/bill_create_wizard";
import {TableGroupSet} from "../group/group_components";

import schtek from '../img/schtek.jpg'

class TableView extends React.Component{
    constructor(props) {
        super(props);
        let tableId = props.match.params.tableId;

        this.state = {
            editMode: false,
            tableId: tableId,
        };

        if (!props.state.tableStates[tableId]) {
            props.state.connection.send({type: "viewTable", tableId});
        }
    }
    render() {
        let state = this.props.state;

        let view;

        let editToggle = function() {
            this.setState({editMode: !this.state.editMode});
        }.bind(this);

        let tableId = this.state.tableId;
        let table = tableId && state.tableStates[tableId];
        let ocr = tableId && state.tableOCR[tableId];

        if (table) {
            let paymentOngoing = table.members.filter(m => m.isYou && m.state === "paymentOngoing").length > 0;

            if (!paymentOngoing) {
                if (this.state.editMode) {
                    view = <BillEdit connection={state.connection} friends={state.friends} tableState={table} editToggle={editToggle} state={state}/>
                } else {
                    view = <Bill connection={state.connection} tableState={table} ocr={ocr} editToggle={editToggle} state={state}/>
                }
            } else {
                view = <BillPay tableState={table} connection={state.connection}/>
            }
        } else {
            view = <div>Loading...</div>
        }

        return view;
    }
}

class BillBack extends React.Component{
    render() {
        let target = this.props.home || "/";
        return (
            <small>
                <Link to={target}>&lt; Back</Link>
            </small>
        );
    }
}
BillBack = withRouter(BillBack);

var imgStyle = {
    maxWidth: "100%"
};
class Bill extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            editMode: props.state.defaultEditMode ||
            props.tableState.groupId ? (props.tableState.author.isYou ? "Assign" : "View") : "Claim",
            hoveringOn: null
        };
        if (props.state.defaultEditMode !== null) {
            getStore().dispatch({type: "SET_DEFAULT_EDIT_MODE", defaultEditMode: null});
        }
    }
    id() {
        return this.props.tableState.tableId;
    }
    startTablePayments() {
        this.props.connection.send({type: "startTablePayments", tableId: this.id()});
    }
    markAsFinished() {
        this.props.connection.send({type: "markAsFinished", tableId: this.id()});
    }
    markAsNotFinished() {
        this.props.connection.send({type: "markAsNotFinished", tableId: this.id()});
    }
    leave() {
        this.props.connection.send({type: "leaveTable", tableId: this.id()});
    }
    delete() {
        this.props.connection.send({type: "deleteTable", tableId: this.id()});
    }
    toggleItem(id) {
        let state = this.props.tableState;
        if (_.some(state.receiptEntries, e => e.id === id)) {
            this.props.connection.send({type: "deleteReceiptEntry", tableId: this.id(), entryId: id});
        } else {
            this.props.connection.send({type: "includeReceiptEntries", tableId: this.id(), entryIds: [id]});
        }
    }
    setHover(index) {
        this.setState({hoveringOn: index})
    }
    render() {
        let state = this.props.tableState;
        let isAuthor = state.author.isYou;
        let tableEntryPhase = state.members.filter(m => m.isYou && (m.state === "notConnected" || m.state === "connected" || m.state === "finished")).length > 0;
        let isFinished = state.members.filter(m => m.isYou && m.state === "finished").length > 0;

        let home = state.groupId ? "/groups/"+state.groupId : "/";

        let rate = null;
        if (state.exchangeRate !== 1 && state.exchangeRate !== 0) {
            rate = (1 / state.exchangeRate) * 1.0175;
        }

        return (
            <div className="row">

                <div className="col-md-12">
                    <h2>
                        <BillBack home={home}/>

                        <button className="btn btn-sm btn-outline-primary float-right" onClick={this.props.editToggle.bind(this)}>Edit Bill</button>
                        <span>
                            <p className="text-center">
                                {state.name || "Unnamed Table"}
                                {!state.groupId ?
                                    <span>
                                        &nbsp; <small>({state.joinCode})</small>
                                    </span>
                                    : null
                                }
                            </p>
                        </span>
                    </h2>
                    <h4>
                        <small>
                            created by {state.author.name} on {state.created.substr(0, 10)}
                        </small>
                    </h4>
                    <h4>
                        <small>
                            latest update on {state.updated.substr(0, 10)}
                        </small>
                    </h4>
                </div>

                <div className="col-md-8">
                    <div>
                        <h3>Members</h3>
                    </div>

                    <Members members={state.members}/>

                    <div>
                        <h3>Entries</h3>

                        <Mismatch mismatch={state.receiptMetadata.amountDifference}/>

                        <TabBar
                            content={{
                                "View": <BillEntriesView tableState={state} hover={(i) => this.setHover(i)} entries={state.receiptEntries}/>,
                                "Claim": <BillEntriesClaim tableState={state} hover={(i) => this.setHover(i)} entries={state.receiptEntries} connection={this.props.connection}/>,
                                "Assign": <BillEntriesAssign tableState={state} hover={(i) => this.setHover(i)} connection={this.props.connection}/>,
                                "Edit": <BillEntriesEdit tableState={state} ocr={this.props.ocr} entries={state.receiptEntries} connection={this.props.connection}/>,
                            }}
                            setActive={(key) => this.setState({editMode: key})}
                            active={this.state.editMode}
                        />
                    </div>

                    {this.state.editMode !== "Edit" ?
                        <div>
                            <h4 className="m-2">
                                Total: {round(state.totalWithExtra)} {state.currency}
                                {state.totalWithExtra !== state.totalAmount ? ` (${state.totalAmount.toFixed(2)} ${state.currency} + Tip)` : null}
                            </h4>
                            {state.totalWithExtra !== state.accounted ?
                                `(${state.accounted.toFixed(2)} claimed, ${(state.totalWithExtra - state.accounted).toFixed(2)} remaining)`
                                : ""
                            }

                            <div className="m-2">
                                <ProgressBar className="m-2" state={state}/>
                            </div>

                            {rate ?
                                <div className="m-2">
                                    Using {state.currency} to SEK exchange rate of {rate.toFixed(2)} (Incl. 1.75% card fees)
                                    the total is {(state.totalWithExtra * rate).toFixed(2)} SEK
                                </div>
                                : null
                            }
                        </div>
                        : null
                    }
                </div>

                <div className="col-md-4" style={{padding: 0}}>
                    <div style={{position: "sticky", top: "20px"}}>
                        <img id="receiptImage" style={imgStyle} src={state.receiptUrl}/>
                        {tableEntryPhase && !isFinished && this.state.editMode === "Edit" ?
                        inclusionBoxes(state, this.props.ocr || {}, this.toggleItem.bind(this))
                        : null }
                        {hoverBox(state, this.state.hoveringOn)}
                    </div>
                </div>

                <div className="col-md-12 mt-3">

                    <div>
                        {tableEntryPhase && !state.groupId ?
                            isAuthor ?
                                <button className="btn btn-success btn-lg btn-block" onClick={this.startTablePayments.bind(this)}>Initiate payment!</button>
                                : !isFinished ?
                                    <button className="btn btn-success btn-lg btn-block" onClick={this.markAsFinished.bind(this)}>Finished!</button>
                                    : <button className="btn btn-default btn-lg btn-block" onClick={this.markAsNotFinished.bind(this)}>Not finished!</button>
                            : null
                        }
                        {haxxEnabled() ?
                            (isAuthor ?
                                <button className="btn btn-danger btn-lg btn-block" onClick={this.delete.bind(this)}>Delete Bill (╯°□°）╯︵ ┻━┻</button>
                                : <button className="btn btn-danger btn-lg btn-block" onClick={this.leave.bind(this)}>Leave Bill (╯°□°）╯︵ ┻━┻</button>)
                            : null }
                    </div>

                    { haxxEnabled() ?
                        <pre>State: {jsonTableStringify(state)}</pre>
                        : null
                    }
                </div>
            </div>
        )
    }
}

function inclusionBoxes(state, ocr, callback) {
    if (haxxEnabled()) {
        window.requestAnimationFrame(function() {
            // TODO import tippy if you want this
            /*
            tippy(".tippy-tooltip", {
                delay: 0,
                arrow: true,
                animateFill: false,
            })
             */
        });
    }

    let used = new Set(state.receiptEntries.map(re => re.id));
    let entries = ocr.entries || [];

    console.log("used", used)

    let blueBorder = "2px solid rgba(150,150,255,0.8)";
    let redBorder = "2px solid rgba(255,150,150,0.8)";
    let lightBackground = "rgba(220,220,255,0.2)";

    return (state.receiptMetadata && state.receiptMetadata.dimensions) ?
        _.concat(
            entries
                .filter(re => used.has(re.id))
                .map(re => {
                    return createBoxes(re, blueBorder, lightBackground, false, callback);
                }),
            entries
                .filter(re => !used.has(re.id))
                .filter(re => re.boundingBox)
                .map(re => {
                    return createBoxes(re, redBorder, lightBackground, false, callback);
                }))
        : null;
}

function hoverBox(state, entryId) {
    if (state.receiptMetadata && state.receiptMetadata.dimensions) {
        return state.receiptEntries.filter(re => re.id == entryId && re.boundingBox).map(re =>
            createBoxes(re, null, "rgba(220,220,255,0.3)", true)
        )
    }

    return null;
}

// Utils for drawing on image
function mergeBox(box1, box2) {
    let xMerge = mergeDimension({start: box1.x, size: box1.w}, {start: box2.x, size: box2.w});
    let yMerge = mergeDimension({start: box1.y, size: box1.h}, {start: box2.y, size: box2.h});
    return {
        x: xMerge.start,
        y: yMerge.start,
        w: xMerge.size,
        h: yMerge.size
    }
};

function mergeDimension(box1, box2) {
    let start = Math.min(box1.start, box2.start);
    let end = Math.max(box1.start + box1.size, box2.start + box2.size);
    let size = Math.max(end - start, 0);
    return {start, size};
};

function createBoxes(re, border, backgroundColor, includeText, callback) {
    let elementHeight = (document.getElementById("receiptImage") || {}).clientHeight || 520;
    let imageHeight = (document.getElementById("receiptImage") || {}).naturalHeight || 1440;
    // let scale = elementHeight / 1255;
    let scale = elementHeight / imageHeight;

    let xpad = 5;
    let ypad = 1;

    let bb = re.boundingBox;
    let renderedBox = null;
    let x,y,w,h;
    if (includeText) {
        renderedBox = mergeBox(bb, {x: bb.textX, y: bb.textY, w: bb.w, h: bb.h});
    } else {
        renderedBox = bb;
    }

    x = Math.round(renderedBox.x * scale) - xpad;
    y = Math.round(renderedBox.y * scale) - ypad;
    w = Math.round(renderedBox.w * scale) + 2 * xpad;
    h = Math.round(renderedBox.h * scale) + 2 * ypad;

    let tooltip = renderedBox.x + "," + renderedBox.y + " " + renderedBox.w + "x" + renderedBox.h;

    return <div className="tippy-tooltip"
                title={tooltip}
                key={re.id} onClick={() => callback(re.id)} style={{
        position: "absolute",
        top: y + "px",
        left: x + "px",
        width: w + "px",
        height: h + "px",
        border: border,
        borderRadius: "5px",
        backgroundColor: backgroundColor
    }}>
    </div>
}

class Members extends React.Component{
    render() {
        var members = this.props.members;

        function stateEmoji(state) {
            let states = {
                "notConnected": "⚪",
                "connected": "🔵",
                "finished": "✅",
                "paymentOngoing": "🔵",
                "paymentFailed": "‼️",
                "paymentComplete": "💰",
                "default": "⚫"
            };
            return states[state] || states["default"];
        }

        let biggestSpend = Math.max(...members.map(m => m.totalAmount));

        return (
            <table className="table table-striped">
                <tbody>
                    {members.map((m) =>
                        <tr key={m.id}>
                            <td className="w-50">
                                <strong>
                                    <Badge member={m} allMembers={members}/>
                                    &nbsp;
                                    {m.name}
                                </strong>
                            </td>
                            <td className="w-50 text-right">
                                {m.totalAmount === biggestSpend ? <span><img src={schtek} width="14ex"/>&nbsp;</span> : null}
                                <strong>{round(m.totalAmount)}</strong>
                            </td>

                        </tr>
                    )}
                </tbody>
            </table>
        )
    }
}

class BillEntriesClaim extends React.Component{
    id() {
        return this.props.tableState.tableId;
    }
    hasClaimedEntry(entry) {
        return entry.claims.filter(c => c.isYou).length > 0;
    }
    claim(entry, e) {
        if (entry.amount != 0) {
            this.props.connection.send({type: "claimReceiptEntry", tableId: this.id(), entryId: entry.id});
        }
    }
    unclaim(entry, e) {
        this.props.connection.send({type: "unclaimReceiptEntry", tableId: this.id(), entryId: entry.id});
    }
    render() {
        var entries = this.props.entries;
        var hover = this.props.hover;

        return (
            <div>
                <table className="table table-striped">
                    <tbody>
                    {entries.map((e) => {
                            let style = e.amount > 0 ? {} : {color: "#777777"}
                            let simplyClaimable = e.shares === 1 && e.claims.length === 0;

                            return <tr key={e.id} onMouseOver={() => hover(e.id)} onMouseLeave={() => hover(null)}>
                                <td>
                                    <table className="table-plain w-100">
                                        <tbody>
                                        <tr>
                                            <td className="w-50" style={style}>
                                                <h5>{entryDesc(e)}</h5>
                                            </td>
                                            <td className="w-25">
                                                <h5>{e.amount ? e.amount.toFixed(2) : ""}</h5>
                                            </td>
                                            <td className="w-25">
                                                <div className="float-right mb-2">
                                                    {this.hasClaimedEntry(e) ?
                                                        <button className="btn btn-sm btn-danger" onClick={this.unclaim.bind(this, e)}>Not mine</button>
                                                        : (simplyClaimable ?
                                                            <button className="btn btn-sm btn-success" onClick={this.claim.bind(this, e)}>Mine</button>
                                                            : null)
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="w-75" colSpan={2}>
                                                {e.claims.map(c =>
                                                <span>
                                                    {(c.count > 1 ? c.count+"x" : "")}
                                                    <Badge member={{id: c.userId, name: c.name}}/>
                                                    &nbsp;
                                                </span>
                                            )}
                                            </td>
                                            <td className="w-25">
                                                <div className="float-right">
                                                    <SplitButton {...this.props} entry={e}/>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        }
                    )}
                    </tbody>
                </table>
            </div>
        )
    }
}

function BillEntriesView(props) {
    let entries = props.entries;
    let hover = props.hover;

    return (
        <div>
            <table className="table table-striped">
                <tbody>
                {entries.map((e) => {
                        let style = e.amount > 0 ? {} : {color: "#777777"}

                        return <tr key={e.id} onMouseOver={() => hover(e.id)} onMouseLeave={() => hover(null)}>
                            <td>
                                <table className="table-plain w-100">
                                    <tbody>
                                    <tr>
                                        <td className="w-50" style={style}>
                                            <h5>{entryDesc(e)}</h5>
                                        </td>
                                        <td className="w-25">
                                            <h5>{e.amount ? e.amount.toFixed(2) : ""}</h5>
                                        </td>
                                        <td className="w-25">
                                            <div className="float-right">
                                                <h5>
                                                    <div>{e.amount ? (e.amount / e.shares).toFixed(2) : ""}</div>
                                                    <div className="float-right">
                                                        <small>Each</small>
                                                    </div>
                                                </h5>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="w-75" colSpan={2}>
                                            {e.claims.map(c =>
                                                <span>
                                                    {(c.count > 1 ? c.count+"x" : "")}
                                                    <Badge member={{id: c.userId, name: c.name}}/>
                                                    &nbsp;
                                                </span>
                                            )}
                                        </td>
                                        <td className="w-25">
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    }
                )}
                </tbody>
            </table>
        </div>
    )
}

function entryDesc(entry) {
    let prefix = "";
    let totalClaims = _.sumBy(entry.claims, 'count');
    if (entry.shares > 1 && entry.shares > totalClaims) {
        prefix = `[${totalClaims}/${entry.shares}] `
    }
    return prefix + entry.description;
}

class BillEntriesAssign extends React.Component{
    id() {
        return this.props.tableState.tableId;
    }
    getClaims(entry, userId) {
        let claim = entry.claims.filter(c => c.userId === userId)[0];
        return claim !== undefined ? claim.count : 0;
    }
    buttonClass(entry, userId) {
        let claims = this.getClaims(entry, userId);
        if (claims > 1) {
            return "btn-primary";
        } else if (claims === 1) {
            return "btn-success";
        } else {
            return "btn-secondary";
        }
    }
    countPrefix(entry, userId) {
        let claims = this.getClaims(entry, userId);
        if (claims > 1) {
            return claims+"x ";
        }
        return "";
    }
    toggleClaim(entry, userId) {
        let claims = this.getClaims(entry, userId) === 0 ? 1 : 0;
        this.props.connection.send({type: "setReceiptEntryClaims", tableId: this.id(), claims: claims, entryId: entry.id, userId: userId});
    }
    render() {
        let table = this.props.tableState;
        let entries = this.props.tableState.receiptEntries;

        return (
            <div>
                <table className="table table-striped">
                    <tbody>
                    {entries.map((e) => {
                            var style = e.amount > 0 ? {} : {color: "#777777"}

                            return <tr key={e.id}>
                                <td>
                                    <table className="table-plain w-100">
                                        <tbody>
                                        <tr onMouseOver={() => this.props.hover(e.id)} onMouseLeave={() => this.props.hover(null)}>
                                            <td className="w-50" style={style}>
                                                <h5>{entryDesc(e)}</h5>
                                            </td>
                                            <td className="w-25">
                                                <h5>{e.amount ? e.amount.toFixed(2) : ""}</h5>
                                            </td>
                                            <td className="w-25">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="w-75" colSpan={2}>
                                                {table.members.map(m => <span key={m.id}>
                                                        <button className={"btn btn-small "+this.buttonClass(e, m.id)} onClick={this.toggleClaim.bind(this, e, m.id)}>
                                                            {this.countPrefix(e, m.id) + uniqueShortName(table.members, m.id)}
                                                        </button>
                                                    &nbsp;
                                                    </span>
                                                )}
                                            </td>
                                            <td className="w-25">
                                                <div className="float-right">
                                                    <SplitButton {...this.props} entry={e}/>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        }
                    )}
                    </tbody>
                </table>
            </div>
        )
    }
}

function SplitButton(props) {
    let entry = props.entry;
    return <ModalController
        modalTitle={entry.description + " - " + entry.amount + " " + props.tableState.currency}
        contentComponent={SplitButtonToggle}
        modalComponent={SplitModal}
        cancelText="Done"
        subProps={props}/>
}

function SplitButtonToggle(props) {
    return <button className="btn btn-sm btn-outline-primary" onClick={props.toggle}>Split</button>
}

function SplitModal(props) {
    let entry = props.entry;

    let tableId = props.tableState.tableId;
    let members = props.tableState.members;
    let sharesByMember = _(entry.claims).keyBy('userId').mapValues(v => v.count).value();

    let totalClaimed = _(sharesByMember).map(v => v).sum();
    let unclaimed = entry.shares - totalClaimed;

    function changeShares(inc) {
        let target = entry.shares + inc;
        if (target >= 1) {
            props.connection.send({type: "setReceiptEntryShares",
                tableId, entryId: entry.id, shares: target});
        }
    }

    function changeClaims(memberId, inc) {
        let memberShares = sharesByMember[memberId] || 0;
        let target = memberShares + inc;
        if (target >= 0) {
            props.connection.send({type: "setReceiptEntryClaims",
                tableId, entryId: entry.id, userId: memberId, claims: target});
        }
    }

    function splitEvenly() {
        members.forEach(m => {
            if (sharesByMember[m.id] !== 1) {
                props.connection.send({type: "setReceiptEntryClaims",
                    tableId, entryId: entry.id, userId: m.id, claims: 1});
            }
        });
        // Note: Shares will automatically increment, so we only need to decrement.
        // Not setting shares will allow auto-decrement to work when you remove someone.
        if (entry.shares > members.length) {
            props.connection.send({type: "setReceiptEntryShares",
                tableId, entryId: entry.id, shares: members.length});
        }
    }

    return <div>
        <table className="table table-striped">
            <tbody>
            <tr>
                <td>
                    <strong>Total shares</strong>
                </td>
                <td>
                    {entry.shares}
                </td>
                <td>
                    <div className="btn-group float-right" role="group" aria-label="Basic example">
                        <button type="button" className="btn" onClick={() => changeShares(-1)}>-</button>
                        <button type="button" className="btn" onClick={() => changeShares(1)}>+</button>
                    </div>
                </td>
            </tr>
            {members.map(m => <tr key={m.id}>
                <td>
                    <strong>{m.name}</strong>
                </td>
                <td>
                    {sharesByMember[m.id] || 0} share{sharesByMember[m.id] !== 1 ? "s" : ""}
                </td>
                <td>
                    <div className="btn-group float-right" role="group" aria-label="Basic example">
                        <button type="button" className="btn" onClick={() => changeClaims(m.id, -1)}>-</button>
                        <button type="button" className="btn" onClick={() => changeClaims(m.id, 1)}>+</button>
                    </div>
                </td>
            </tr>)}
            {unclaimed > 0 ?
                <tr>
                    <td>
                        (unclaimed)
                    </td>
                    <td>
                        {unclaimed}
                    </td>
                </tr>
                : null}
            </tbody>
        </table>
        <button className="btn btn-info" onClick={() => splitEvenly()}>Split equally</button>
    </div>
}

class BillEntriesEdit extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            changedEntries: {}
        };
        this.props.connection.send({type: "getReceiptOcr", tableId: this.id()})
    }
    id() {
        return this.props.tableState.tableId;
    }
    getChange(eventId) {
        return this.state.changedEntries[eventId] || {};
    }
    addEntry() {
        this.props.connection.send({type: "createReceiptEntry", tableId: this.id(), description: "", amount: 0});
    }
    removeEntry(entryId) {
        this.props.connection.send({type: "deleteReceiptEntry", tableId: this.id(), entryId: entryId});
    }
    changeShares(entryId, event) {
        var changes = this.state.changedEntries;
        changes[entryId] = this.getChange(entryId);
        changes[entryId].shares = event.target.value;
        this.setState({changedEntries: changes});
    }
    changeDesc(entryId, event) {
        var changes = this.state.changedEntries;
        changes[entryId] = this.getChange(entryId);
        changes[entryId].description = event.target.value;
        this.setState({changedEntries: changes});
    }
    changeAmount(entryId, event) {
        var changes = this.state.changedEntries;
        changes[entryId] = this.getChange(entryId);
        changes[entryId].amount = event.target.value;
        this.setState({changedEntries: changes});
    }
    saveChangedDesc(entryId) {
        var change = this.getChange(entryId);
        if (change.description || change.amount) {
            this.props.connection.send({
                type: "updateReceiptEntry",
                entryId: entryId,
                description: change.description,
                amount: change.amount,
                tableId: this.id(),
            });
        }
        if (change.shares) {
            this.props.connection.send({
                type: "setReceiptEntryShares",
                entryId: entryId,
                shares: change.shares,
                tableId: this.id(),
            });
        }
    }
    discardChange(entryId) {
        var changes = this.state.changedEntries;
        delete changes[entryId];
        this.setState({changedEntries: changes});
    }
    hasChanges(entry) {
        return (this.getChange(entry.id).shares && this.getChange(entry.id).shares !== entry.shares) ||
            (this.getChange(entry.id).description && this.getChange(entry.id).description !== entry.description) ||
            (this.getChange(entry.id).amount && this.getChange(entry.id).amount !== entry.amount);
    }
    saveAll() {
        this.props.entries.map(entry => {
            if (this.hasChanges(entry)) {
                this.saveChangedDesc(entry.id);
            }
        });
    }
    componentWillUnmount() {
        this.saveAll();
    }
    render() {
        // TODO update mismatch in realtime as edits are made??
        var entries = this.props.entries;

        return (
            <div>
                <div className="m-2">
                    {entries.map((e) => {
                            var style = e.amount > 0 ? {} : {color: "#777777"}

                            return <div key={e.id} className="form-row mb-2">
                                <div className="col-6 col-md-8">
                                    <input className="form-control" type="text" value={or(this.getChange(e.id).description, e.description)} onChange={this.changeDesc.bind(this, e.id)}/>
                                </div>
                                <div className="col-3">
                                    <input className="form-control" type="text" value={or(this.getChange(e.id).amount, e.amount) || ""} onChange={this.changeAmount.bind(this, e.id)}/>
                                </div>
                                <div className="col-3 col-md-1">
                                    {
                                        this.hasChanges(e) ?
                                            <div className="btn-group">
                                                <button className="btn btn-default" onClick={this.discardChange.bind(this, e.id)}>Undo</button>
                                            </div>
                                            : <button className="btn btn-danger" onClick={this.removeEntry.bind(this, e.id)}>Delete</button>
                                    }
                                </div>
                            </div>
                        }
                    )}
                </div>

                <div className="col-12">
                    <button className="btn btn-success float-right" onClick={this.addEntry.bind(this)}>Add row</button>
                </div>
                { haxxEnabled() ?
                    <pre>OCR: {jsonTableStringify(this.props.ocr)}</pre>
                    : null
                }
            </div>
        )
    }
}

function Mismatch(props) {
    return <div>
        {props.mismatch !== undefined && Math.abs(props.mismatch) > 0.01 ?
            <div className="alert alert-danger" role="alert">
                <strong>Warning! </strong>
                Mismatch between table amount and recognized entries. Difference is {props.mismatch.toFixed(2)}
            </div>
            : null
        }
    </div>
}

function or(a, b) {
    if (a === null || a === undefined) {
        return b;
    }
    return a;
}

class ProgressBar extends React.Component{
    render() {
        var state = this.props.state;

        if (state.totalWithExtra <= 0 || state.accounted > state.totalWithExtra) {
            return (<div className="progress">
                <div className="progress-bar progress-bar-danger" role="progressbar" style={{width: "100%"}}>
                    {state.totalWithExtra <= 0 ? "No total for table!" : "Amounts exceeds total!"}
                </div>
            </div>)
        }

        var myAmount = state.members.filter(m => m.isYou)[0].totalAmount;
        var myPart = Math.round(myAmount * 100 / state.totalWithExtra);
        var othersPart = Math.round(state.accounted * 100 / state.totalWithExtra) - myPart;

        var everythingAccounted = Math.abs(state.accounted - state.totalWithExtra) < 0.1;
        var myBarClass = everythingAccounted ? "bg-info" : "bg-info";
        var otherBarClass = everythingAccounted ? "bg-success" : "bg-warning";

        return (<div>
            <div className="progress">
                <div className={"progress-bar "+myBarClass} role="progressbar" style={{width: ""+myPart+"%"}}>
                    {round(myAmount)}
                </div>

                <div className={"progress-bar "+otherBarClass} role="progressbar" style={{width: ""+othersPart+"%"}}>
                    {round(state.accounted - myAmount)}
                </div>
            </div>
        </div>)
    }
}

function makeSetterComponent(placeholder, buttonText, buttonClass, messageType, fieldName, inputType) {
    return class Inner extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                newEntry: this.props.defaultValue || ""
            };
        }
        inputEntry(e) {
            this.setState({newEntry: e.target.value});
        }
        submitEntry() {
            var input = this.state.newEntry;
            if (inputType == "number") {
                input = +input;
                if (Number.isNaN(+input)) {
                    getStore().dispatch({type: "ADD_ERROR", error: "Amount must be numeric!"});
                    return;
                }
            }


            var message = {type: messageType};
            if (this.props.extraData) {
                Object.assign(message, this.props.extraData);
            }
            message[fieldName] = input;
            this.props.connection.send(message);
            if (!this.props.defaultValue) {
                this.setState({newEntry: ""});
            }
        }
        render() {
            let type = {
                number: "number",
                text: "text",
                formula: ""
            }[inputType];

            let inputmode = {
                number: "numeric",
                text: "latin",
                formula: "numeric"
            }[inputType];

            return (
                <form onSubmit={this.submitEntry}>
                    <div className="input-group">
                        <input inputMode={inputmode} className="form-control" placeholder={placeholder}
                               value={this.state.newEntry} onChange={this.inputEntry.bind(this)}/>
                    <span className="input-group-btn">
                        <button className={buttonClass} type="button" onClick={this.submitEntry.bind(this)}>{buttonText}</button>
                    </span>
                    </div>
                </form>
            )
        }
    }
}

class BillEdit extends React.Component{
    constructor(props) {
        super();
        this.state = {
            name: props.tableState.name,
            totalAmount: props.tableState.totalAmount,
            currency: props.tableState.currency,
        }
    }
    setField(field, e) {
        let value = e.target ? e.target.value : e;
        this.setState({[field]: value});
    }
    id() {
        return this.props.tableState.tableId;
    }
    addMember(id, e) {
        this.props.connection.send({type: "addPersonToTable", id: id, tableId: this.id()});
        if (e) {
            e.preventDefault();
        }
    }
    removeMember(member) {
        this.props.connection.send({type: "removePersonFromTable", id: member.id, tableId: this.id()});
    }
    componentWillMount() {
        let groupId = this.props.tableState.groupId;
        if (groupId) {
            this.props.state.connection.send({type: "getGroup", groupId});
        }
    }
    saveChanges() {
        if (this.state.name !== this.props.tableState.name) {
            this.props.connection.send({type: "setNameOfTable", tableId: this.id(), name: this.state.name});
        }
        if (this.state.totalAmount !== this.props.tableState.totalAmount ||
            this.state.currency !== this.props.tableState.currency) {
            this.props.connection.send({type: "setTotalForTable", tableId: this.id(),
                totalAmount: this.state.totalAmount, currency: this.state.currency});
        }
        this.props.editToggle();
    }
    leave() {
        if (window.confirm("Are you sure you want to abandon the table?")) {
            this.props.state.connection.send({type: "leaveTable", tableId: this.id()});
        }
    }
    delete() {
        if (window.confirm("Are you sure you want to permanently the table?")) {
            this.props.state.connection.send({type: "deleteTable", tableId: this.id()});
        }
    }
    render() {
        let state = this.props.tableState;

        let isAuthor = state.author.isYou;

        let groupId = state.groupId;
        let group = this.props.state.groupStates[groupId] || {};
        let memberCandidates = group.members || this.props.state.friends;

        return <div className="row">
            <div className="col-md-12">
                <h2>
                    <button className="btn btn-sm btn-success float-right" onClick={this.saveChanges.bind(this)}>Done</button>
                </h2>
            </div>
            &nbsp;
            <div className="col-md-12">
                <h2>
                    <span className="text-center">Edit Bill {state.name || "Unnamed"} </span>
                </h2>
            </div>

            <div className="col-md-4">
                <h3>Photo</h3>
                {isAuthor ?
                    <TableImageUpload title="Upload New Photo" autoUpload={true} tableId={state.tableId}/>
                    : null}
                <img style={imgStyle} src={state.receiptUrl}/>
            </div>

            <div className="col-md-8">
                <h3>Total: {state.totalAmount}</h3>

                {isAuthor ?
                    <div>
                        <form onSubmit={this.saveChanges}>
                            <input className="form-control" placeholder={"Name"} type="text"
                                   value={this.state.name} onChange={e => this.setField("name", e)}/>
                            <input className="form-control" placeholder={"Amount"} type="number"
                                   value={this.state.totalAmount} onChange={e => this.setField("totalAmount", e)}/>
                            <CurrencySelect defaultValue={this.state.currency}
                                            setValue={value => this.setField("currency", value)}/>
                        </form>
                    </div>
                    : null }

                <div className="mt-3">
                    <h3>Members</h3>

                    <table className="table table-striped">
                        <tbody>
                        {state.members.map((m) =>
                            <tr key={m.id}>
                                <td>
                                    <strong>{m.name}</strong>
                                </td>
                                <td>
                                    {isAuthor && !m.isYou ?
                                        <button className="btn btn-danger float-right" onClick={this.removeMember.bind(this,m)}>Remove!</button>
                                        : null}
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>

                    <h4>Add friends</h4>

                    <table className="table table-striped">
                        <tbody>
                        {_(memberCandidates)
                            .filter(m => state.members.filter(am => am.id === m.id).length === 0)
                            .map(m =>
                                <tr key={m.id}>
                                    <td>
                                        <strong>{m.name}</strong>
                                    </td>
                                    <td>
                                        <button className="btn btn-success float-right" onClick={this.addMember.bind(this,m.id)}>Add!</button>
                                    </td>
                                </tr>
                            ).value()}
                        </tbody>
                    </table>

                    <h4>Bill Group</h4>

                    <TableGroupSet tableId={state.tableId} groupId={state.groupId} state={this.props.state}/>

                    {state.author.isYou ?
                        <button className="btn btn-danger btn-lg btn-block" onClick={this.delete.bind(this)}>Delete Bill (╯°□°）╯︵ ┻━┻</button>
                        : <button className="btn btn-danger btn-lg btn-block" onClick={this.leave.bind(this)}>Leave Bill (╯°□°）╯︵ ┻━┻</button>
                    }
                </div>
            </div>
        </div>
    }
}

class BillPay extends React.Component{
    render() {
        let state = this.props.tableState;

        return (
            <div>
                <p>Paying in non-group bills is not supported in webclient.</p>

                { haxxEnabled() ?
                    <pre>State: {jsonTableStringify(state)}</pre>
                    : null
                }
            </div>
        )
    }
}

export { TableView }
