import React from 'react';
import {Link, withRouter} from "react-router-dom";
import {BillCreateStart} from "../bill_create/bill_create_components";

class BillList extends React.Component{
    componentDidMount() {
        this.props.connection.send({type: "tablesOngoing"});
        this.props.connection.send({type: "tablesCompleted"});
        this.props.connection.send({type: "getFriends"});
    }
    render() {
        let tablesOngoing = this.props.state.tablesOngoing;
        let tablesCompleted = this.props.state.tablesCompleted;

        return (
            <div>
                <div className="row">
                    <div className="col">
                        <BillCreateStart/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6">
                        <div>
                            <h3 className="mt-3 mt-3">Ongoing Bills</h3>

                            {tablesOngoing.map(table => <BillJoin key={table.tableId} table={table} connection={this.props.connection}/>)}
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div>
                            <h3 className="mt-3 mt-3">Settled Bills</h3>


                            {tablesCompleted.map(table => <BillJoin key={table.tableId} table={table} connection={this.props.connection}/>)}
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

class BillJoin extends React.Component{
    join(tableId) {
        let table = this.props.table;
        let command = (table.atTable === undefined || table.atTable === true) ? "viewTable" : "joinTable";
        this.props.connection.send({type: command, tableId: tableId});
        this.props.history.push("/bill/"+tableId);
    }
    render() {
        let table = this.props.table;

        return (
            <div>
                <h4>
                    <a href="#" onClick={(e) => {e.preventDefault(); this.join(table.tableId);}}>{table.name}</a>
                </h4>
                <div>by {(table.author || {}).name}, {(table.members || []).length} members</div>
                {table.totalAmount ?
                    <div><strong>{table.yourAmount}/{table.totalAmount} {table.currency}</strong></div>
                    : null}
                <div>{(table.created || "").substring(0,19).replace("T", " ")}</div>
            </div>
        );
    }
}
BillJoin = withRouter(BillJoin);

export {BillList as TableList}
