import _ from 'lodash'
import React, {useState} from 'react';
import {getStore} from "../common/redux_store";
import {haxxEnabled} from "../common/utils";

export function Settings(props) {
    let userName = _.get(props, 'state.userState.publicName') || "";

    function switchUser() {
        getStore().dispatch({type: "LOG_OUT"});
    }

    return <div>
        <div className="text-right">
            <strong>Logged in as {userName} </strong>
            <button className="btn btn-default" onClick={switchUser}>Logout</button>
        </div>

        {props.state.userState.token ?
            <UserSettings {...props}/>
            : null}

        {haxxEnabled() ? <DebugSend {...props}/> : null}
    </div>
}

export function UserSettings(props) {
    let originalName = _.get(props, 'state.userState.publicName') || "";
    let originalEmail = _.get(props, 'state.userState.email') || "";
    let originalPhone =_.get(props, 'state.userState.phone') || "";

    let [name, setName] = useState(originalName);
    let [email, setEmail] = useState(originalEmail);
    let [phone, setPhone] = useState(originalPhone);

    function saveChanges() {
        if (name !== originalName) {
            props.state.connection.send({type: "changePublicName", publicName: name})
        }
        if (email !== originalEmail) {
            props.state.connection.send({type: "registerEmail", email})
        }
        if (phone !== originalPhone) {
            props.state.connection.send({type: "changePhone", phone})
        }
        props.state.history.push("/");
    }

    return <div>
        <form>
            <div className="form-group">
                <label htmlFor="tokenInput">Public Name: </label>
                <input type="text" className="form-control" value={name} onChange={e => setName(e.target.value)}/>
            </div>

            <div className="form-group">
                <label htmlFor="tokenInput">Email: </label>
                <input type="text" className="form-control" value={email} onChange={e => setEmail(e.target.value)}/>
            </div>

            <div className="form-group">
                <label htmlFor="tokenInput">Phone: </label>
                <input type="text" className="form-control" value={phone} onChange={e => setPhone(e.target.value)}/>
            </div>

            <button type="submit" className="btn btn-success" onClick={saveChanges}>Save</button>
        </form>
    </div>
}

function DebugSend(props) {
    let [msg, setMsg] = useState("type ");

    function sendMsg(e) {
        window.send(msg);
        e.preventDefault();
    }

    return <div>
        <h5 className="mt-2">User state</h5>

        <pre>
            {JSON.stringify(props.state.userState, null, 4)}
        </pre>

        <h4>Debug send</h4>

        <form>
            <input className="form-control" value={msg} onChange={e => setMsg(e.target.value)}/>
            <button type="submit" className="btn btn-success" onClick={sendMsg}>Send</button>
        </form>

        <h5 className="mt-2">Last message</h5>

        <pre>
            {JSON.stringify(props.state.lastMessage, null, 4)}
        </pre>
    </div>
}
