import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {BillCreateStart} from "../bill_create/bill_create_components";
import {getUserId, haxxEnabled} from "../common/utils";
import {Badge, FloatInput, shortName} from "../common/common_components";
import _ from "lodash";
import Swish from "../common/Swish";
import {getStore} from "../common/redux_store";

import schtek from '../img/schtek.jpg'

export function GroupList(props) {
    useEffect(() => props.state.connection.send({type: "listGroups"}), []);

    var groups = props.state.groupList;
    groups = _.sortBy(groups, ["lastUpdated", "name"])
    groups = groups.reverse()

    var yourId = _.get(props, 'state.userState.publicUserId')

    return <div>
        <h3>Your Groups</h3>
        <div>
            {groups.map(g =>
            <div key={g.id} class="mb-2">
                <Link to={"/groups/"+g.id}>
                    <h4>{g.name}</h4>
                </Link>
                {g.balances[yourId] !== undefined ? <Balance balance={g.balances[yourId]}/> : null}
                {g.lastUpdated ? <div>Last updated: {g.lastUpdated.substring(0,10)}</div> : null}
            </div>
            )}
        </div>

        <hr/>

        <Link to={"/groups/create"}>Create new group</Link>
    </div>
}

function Balance(props) {
    var balance = props.balance;

    if (balance == 0) {
        return <div class="text-success">✅ Settled up</div>
    } else if (balance > 0) {
        return <div class="text-success">💰 {balance.toFixed(0)} kr in debt securities </div>
    } else {
        return <div class="text-danger">💸 {Math.abs(balance).toFixed(0)} kr in unpaid debts </div>
    }
}

export function GroupCreate(props) {
    let [name, setName] = useState("");

    function createGroup() {
        props.state.connection.send({type: "createGroup", name});
        setName("");
    }

    return <div>
        <h3>Create Group</h3>
        <div>
            <div>
                <div className="form-group">
                    <label>Name: </label>
                    <input type="text" className="form-control" placeholder={"Name"} value={name}
                           onChange={e => setName(e.target.value)}/>
                </div>
            </div>
        </div>

        <button className="btn btn-success btn-lg btn-block mb-2" disabled={!name} onClick={createGroup}>Create Group</button>

    </div>
}

export function GroupView(props) {
    let groupId = props.match.params.groupId;
    let [detailedTable, setDetailedTable] = useState(false);
    useEffect(() => props.state.connection.send({type: "getGroup", groupId: groupId}), []);

    let group = props.state.groupStates[groupId];
    if (!group) {
        return <div>Loading...</div>
    }

    let lastDate = null;
    let tableList = [];

    for (let event of group.events) {
        let date = event.date.substring(0, 10);
        if (date !== lastDate) {
            tableList.push({dateHeader: date});
            lastDate = date;
        }
        tableList.push(event);
    }

    let state = props.state;

    function getMember(id) {
        return group.members.filter(m => m.id === id)[0];
    }

    function paymentType(paymentType) {
        return {
            "unverified": "unverified payment",
            "swish": "swish",
        }[paymentType] || paymentType
    }

    let activeMembers = group.members.filter(m => m.state === "active" || m.balance !== 0);
    let biggestSpend = Math.max(...activeMembers.map(m => m.spent));
    let yourId = _.get(props, 'state.userState.publicUserId');
    let you = group.members.filter(m => m.id === yourId)[0];

    return <div>
        <button className="btn btn-sm btn-outline-primary float-right"
                onClick={() => state.history.push("/groups/"+groupId+"/edit")}>Edit Group</button>

        <h1>{group.name}</h1>

        <h3>Your Debts</h3>

        {you.balance < 0 ?
            <div>
                <div>
                    <strong className="text-danger">You have {-you.balance} kr in unpaid debts!</strong>
                </div>

                <button className="btn btn-danger btn-lg btn-block mt-3 mb-3" onClick={() => state.history.push("/groups/"+groupId+"/settle")}> Pay Your Debts!</button>
            </div>
            : <div>
                <div>
                    <p>You have no debts to pay in this group.</p>
                    <p>Other people owe you {you.balance} kr.</p>
                </div>

                <button className="btn btn-outline-success btn-lg btn-block mt-3 mb-3" onClick={() => state.history.push("/groups/"+groupId+"/settle")}>See Payments</button>
            </div>
        }

        <BillCreateStart groupId={group.groupId}/>

        <h3>Members</h3>

        <table className="table table-striped">
            <tbody>
            <tr>
                <th> <strong>Member</strong> </th>
                <th> <strong>Financial Standing</strong> </th>
                <th className="d-none d-sm-table-cell">
                    <strong>Total Spend</strong>
                </th>
                <th>
                    <button className="btn btn-outline-secondary" onClick={() => setDetailedTable(!detailedTable)}>?</button>
                </th>
            </tr>
            {activeMembers.map(m => <tr key={m.id}>
                <td>
                    <Badge member={m} allMembers={group.members} />
                    &nbsp;
                    {m.name}
                </td>

                <td>
                    <Balance balance={m.balance}/>
                </td>

                <th className="d-none d-sm-table-cell">
                    {m.spent.toFixed(0)} kr
                    {m.spent === biggestSpend ? <span> <img src={schtek} width="16ex"/></span> : null}

                </th>

                <td>
                    {detailedTable ? <div>
                        <div>
                            + {m.paid.toFixed(0)} paid at tables
                        </div>
                        <div>
                            - {m.spent.toFixed(0)} spent at tables
                        </div>
                        <div>
                            + {m.settleSent.toFixed(0)} paid to others in group
                        </div>
                        <div>
                            - {m.settleReceived.toFixed(0)} received from others in group
                        </div>
                        <div>
                            = {m.balance.toFixed(0)} total balance
                        </div>
                    </div> : null}
                </td>
                </tr>
            )}
            </tbody>
        </table>

        <h3 class="mt-3">Group Bills</h3>

        <table className="table table-striped">
            <tbody>
            <tr>
                <th> <strong></strong> </th>
                <th> <strong>Spending</strong> </th>
                <th> <strong>Your Balance</strong> </th>
            </tr>
            {tableList.map((event, index) => {
                if (event.dateHeader) {
                    return <tr key={event.dateHeader} className="text-info">
                        <td colSpan={3}>
                            <small>{event.dateHeader}</small>
                        </td>
                    </tr>
                }

                if (event.settle) {
                    let settle = event.settle;
                    return <tr key={index}>
                        <td colSpan={2}>
                            <div>
                                <strong>{getMember(settle.from).name}</strong>
                                <span> sent {settle.amount} kr to </span>
                                <strong>{getMember(settle.to).name}</strong>
                                <span> using </span>
                                {paymentType(settle.paymentType)}
                            </div>
                            {settle.message ? <div>
                                Comment: {settle.message}
                            </div> : null}
                            {settle.registeredBy !== settle.from ? <div>
                                Registed by: {getMember(settle.registeredBy).name}
                            </div> : null}
                        </td>
                        <td>
                            <span className={event.balance < 0 ? 'text-danger' : 'text-success'}>
                                {event.balance.toFixed(0)} kr
                            </span>
                        </td>
                    </tr>
                }

                let t = event.table;
                let updated = null;
                if ((new Date(t.updated) - new Date(event.date)) > 3 * 24 * 3600 * 1000) {
                    updated = t.updated;
                }

                return <tr key={t.id}>
                    <td style={{"width": "40%"}}>
                        <div style={{'fontSize': '1.1em'}} className="mb-2">
                            <div className="link-color">
                                <Link to={"/bill/" + t.id}>
                                    {t.name}
                                </Link>
                            </div>
                        </div>
                        <div className="mt-1">
                            <span className="d-none d-sm-inline"> <small>by</small> </span>
                            {t.members.slice(0, 1)
                                .map(id => getMember(id))
                                .map(m => <Badge style={{margin: "2px"}} key={m.id} member={m} allMembers={group.members}/>)}
                            <span className="d-none d-sm-inline"> <small>with</small></span>
                            {t.members.slice(1)
                                .map(id => getMember(id))
                                .map(m => <span key={m.id}>
                                    <span> </span>
                                    <Badge member={m} allMembers={group.members}/>
                                </span>
                                )}
                        </div>
                        <div>
                            {updated ?
                                <small className="text-warning">
                                    Updated on: {updated.substr(0, 10)}
                                </small>
                            : null}
                        </div>
                    </td>
                    <td style={{"width": "30%"}}>
                        <div>
                            {t.spent.toFixed(2)} {t.currency}
                        </div>
                        {t.paid > 0 || t.members[0] === _.get(props, 'state.userState.publicUserId') ?
                            <div>
                                <small>
                                    You paid {t.paid.toFixed(2)} {t.currency}
                                </small>
                            </div>
                            : null
                        }
                        {t.unclaimed > 0 ?
                            <div>
                                <small className="text-warning">
                                    Still unclaimed {t.unclaimed.toFixed(2)} {t.currency}
                                </small>
                            </div>
                            : null
                        }
                    </td>
                    <td style={{"width": "30%"}}>
                        <span className={event.balance < 0 ? 'text-danger' : 'text-success'}>
                            {event.balance.toFixed(0)} kr
                        </span>
                    </td>
                </tr>
            })}
            </tbody>
        </table>

        {haxxEnabled() ? <pre>{JSON.stringify(group, null, 4)}</pre> : null}
    </div>
}

export function GroupEditLookup(props) {
    let groupId = props.match.params.groupId;
    useEffect(() => props.state.connection.send({type: "getGroup", groupId: groupId}), []);

    let group = props.state.groupStates[groupId];
    if (!group) {
        return <div>Loading...</div>
    }

    return <GroupEdit {...props} group={group}/>
}

function GroupEdit(props) {
    useEffect(() => {
        props.state.connection.send({ type: "getFriends" });
        props.state.connection.send({ type: "listGroups" });
    }, []);

    let group = props.group;

    let [name, setName] = useState(group.name);
    let [newMember, setNewMember] = useState("");
    let [newGuest, setNewGuest] = useState("");

    let groupMembersIds = new Set(group.members.map(m => m.id));
    let friendCandidates = props.state.friends.filter(f => !groupMembersIds.has(f.id) && !f.name.includes("(Guest)"));

    let friendInfo = {}
    if (props.state.groupList) {
        for (let g of props.state.groupList) {
            for (let friendId in g.balances) {
                let info = friendInfo[friendId] || {shared: 0, latest: ""}
                friendInfo[friendId] = info;

                info.shared += 1;
                if (g.lastUpdated > info.latest) {
                    info.latest = g.lastUpdated;
                }
            }
        }
    }

    // sort list based on most shared groups, and most recent group as tie breaker
    friendCandidates = friendCandidates.sort((a, b) => {
        let aInfo = friendInfo[a.id] || {shared: 0, latest: ""};
        let bInfo = friendInfo[b.id] || {shared: 0, latest: ""};
        if (bInfo.shared === aInfo.shared) {
            return bInfo.latest.localeCompare(aInfo.latest);
        }
        return bInfo.shared - aInfo.shared;
    })

    function save() {
        if (name !== group.name) {
            props.state.connection.send({type: "setNameOfGroup", groupId: group.groupId, name});
        }
        props.state.history.push("/groups/"+group.groupId)
    }

    function addMember() {
        let msg = {type: "addMemberToGroup", groupId: group.groupId};
        if (newMember.includes("@")) {
            msg.email = newMember;
        } else {
            msg.userId = newMember;
        }
        props.state.connection.send(msg);
        props.state.connection.send({type: "getGroup", groupId: group.groupId});
        setNewMember("");
    }

    function deactiveMember(id) {
        props.state.connection.send({type: "inactivateGroupMember", groupId: group.groupId, userId: id});
        props.state.connection.send({type: "getGroup", groupId: group.groupId});
    }

    function addMemberById(id) {
        props.state.connection.send({type: "addMemberToGroup", groupId: group.groupId, userId: id});
        props.state.connection.send({type: "getGroup", groupId: group.groupId});
    }

    function addGuest(name) {
        props.state.connection.send({type: "addGuestToGroup", groupId: group.groupId, name});
        props.state.connection.send({type: "getGroup", groupId: group.groupId});
        setNewGuest("");
    }

    function leaveGroup(id) {
        if (window.confirm("Are you sure you want to lose access to the group?")) {
            props.state.connection.send({type: "leaveGroup", groupId: group.groupId});
            props.state.history.push("/");
        }
    }

    return <div>
        <div>
            <button className="btn btn-sm btn-success float-right" onClick={save}>Done</button>
        </div>

        <h2>Edit Group</h2>

        <div>
            <div className="form-group">
                <label>Name: </label>
                <input type="text" className="form-control" placeholder={"Name"} value={name}
                       onChange={e => setName(e.target.value)}/>
            </div>
        </div>


        <h3 className="mt-5">Active Members</h3>

        <div>
            The currently active members of the group can be added to new bills.
        </div>

        <table className="table table-striped">
            <tbody>
            {group.members.filter(m => m.state === "active").map((m) =>
                <tr key={m.id}>
                    <td>
                        <strong>{m.name}</strong>
                    </td>
                    <td>
                        <button className="btn btn-danger float-right" onClick={e => deactiveMember(m.id)}>Remove</button>
                    </td>
                </tr>
            )}
            </tbody>
        </table>

        <h3>Previous Members</h3>

        <div>
            Debts and balances of previous members are still tracked. Previous members are hidden from the list if their debts are settled.
        </div>

        <table className="table table-striped">
            <tbody>
            {group.members.filter(m => m.state !== "active").map((m) =>
                <tr key={m.id}>
                    <td>
                        <strong>{m.name}</strong>
                    </td>
                    <td>
                        <button className="btn btn-success float-right" onClick={e => addMemberById(m.id)}>Reinstate</button>
                    </td>
                </tr>
            )}
            </tbody>
        </table>

        <h3 className="mt-5">Add New Member</h3>

        <div>
            <div className="form-group">
                <label>New member: </label>
                <input type="text" className="form-control" placeholder={"Email"} value={newMember}
                       onChange={e => setNewMember(e.target.value)}/>
            </div>
        </div>

        <button className="btn btn-success btn-lg btn-block mb-2" disabled={!newMember} onClick={addMember}>Add new member</button>

        <h4>Friends To Add</h4>

        <table className="table table-striped">
            <tbody>
            {friendCandidates.map((m) =>
                <tr key={m.id}>
                    <td>
                        <strong>{m.name}</strong>
                        <div>
                            <small>You are in {(friendInfo[m.id] || {}).shared || 0} groups together</small>
                        </div>
                    </td>
                    <td>
                        <button className="btn btn-success float-right" onClick={e => addMemberById(m.id)}>Add</button>
                    </td>
                </tr>
            )}
            </tbody>
        </table>

        {friendCandidates.length === 0 ?
            <div>You have no friends that are not already in this group.</div>
            : null
        }

        <h3 className="mt-5">Add New Guest</h3>

        <div>
            Guests are members that do not have a login and only exist within this group. You can assign bill items to them and then register manual payments or transfer their debt to another member.
        </div>

        <div>
            <div className="form-group">
                <label>New guest: </label>
                <input type="text" className="form-control" placeholder={"John Doe"} value={newGuest}
                       onChange={e => setNewGuest(e.target.value)}/>
            </div>
        </div>

        <button className="btn btn-success btn-lg btn-block mb-2" disabled={!newGuest} onClick={() => addGuest(newGuest)}>Add new guest</button>

        <h3 className="mt-5">Leave Group</h3>

        <div>
            Removes you from the group so you can no longer view it. Only you can take this action.
        </div>


        <button className="mt-3 btn btn-danger btn-lg btn-block" onClick={() => leaveGroup()}>Leave Group (╯°□°）╯︵ ┻━┻</button>
    </div>
}

export function GroupSettleLookup(props) {
    let groupId = props.match.params.groupId;
    useEffect(() => props.state.connection.send({type: "getGroup", groupId: groupId}), []);

    let group = props.state.groupStates[groupId];
    if (!group) {
        return <div>Loading...</div>
    }

    return <GroupSettle {...props} group={group}/>
}

export function GroupSettle(props) {
    let groupId = props.match.params.groupId;
    let group = props.group;
    let state = props.state;

    useEffect(() => {
        // Swish payment results (this could be extracted later)
        const urlParams = new URLSearchParams(window.location.search);
        const res = JSON.parse(decodeURIComponent(urlParams.get("res"))) || {};

        // Make sure to only parse result once
        if (window.location.href.includes("?")) {
            window.history.replaceState({}, document.title, window.location.href.split("?")[0])
        }

        if (res.result === "notpaid" && res.userInfo) {
            getStore().dispatch({type: "ADD_ERROR", error: res.userInfo.message});
        } else if (res.result === "paid") {
            let phoneNumber = res.payee;
            if (phoneNumber.startsWith("0")) {
                phoneNumber = "46"+phoneNumber.slice(1);
            }

            let payment = group.suggestedPayments.filter(p => p.swishPhone === phoneNumber)[0];
            if (payment) {
                props.state.connection.send({
                    type: "addGroupSettlement",
                    groupId: group.groupId,
                    from: getUserId(props.state),
                    to: payment.to,
                    amount: res.amount,
                    paymentType: "swish",
                    message: "",
                });
                props.state.connection.send({"type": "getGroup", "groupId": group.groupId})
            } else {
                getStore().dispatch({type: "ADD_ERROR", error: "Unable to find matching payment. Double check and register manually if needed."});
            }
        }
    }, []);

    function getMember(id) {
        return group.members.filter(m => m.id === id)[0] || {};
    }

    function paidDesktop(suggestedPayment, index) {
        let paymentType = "unverified";
        if (suggestedPayment.swishPhone) {
        if(!window.confirm("On mobile you can pay with Swish, on desktop the payment will be marked as unverified.")) {
            return;
        }
        } else {
            if(!window.confirm("The user does not have a valid swish number. You can register a manual payment but the payment will be marked as unverified.")) {
                return;
            }
        }

        props.state.connection.send({
            type: "addGroupSettlement",
            groupId: group.groupId,
            from: suggestedPayment.from,
            to: suggestedPayment.to,
            amount: suggestedPayment.amount,
            paymentType: paymentType,
            message: "",
        });
        group.suggestedPayments.splice(index, 1);
        props.state.connection.send({"type": "getGroup", "groupId": group.groupId})
    }

    function isMobile() {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    }

    let yourPayments = group.suggestedPayments.filter(p => getUserId(props.state) === p.from)
    let othersPayments = group.suggestedPayments.filter(p => getUserId(props.state) !== p.from);

    return <div>
        <Link to={"/groups/"+groupId}>&lt; Back</Link>

        <h4>Your Payments</h4>

        {yourPayments.length > 0 ? 
            <table className="table">
                <tbody>
                <tr>
                    <th>From</th>
                    <th>To</th>
                    <th>Amount</th>
                    <th></th>
                </tr>
                {yourPayments.map((p, i) => {
                    return <tr key={i}>
                        <td>{getMember(p.from).name}</td>
                        <td>{getMember(p.to).name}</td>
                        <td>{p.amount} kr</td>
                        <td>
                            { isMobile() && p.swishPhone ?
                                <a href={Swish.getSwishUrl(p.swishPhone, p.amount, group.name + " fish group settle")}>
                                    <button className="btn btn-success">Pay with Swish</button>
                                </a>
                                :
                                <button className="btn btn-success" onClick={() => paidDesktop(p, i)}>Paid</button>
                            }
                        </td>
                    </tr>
                }) }
                </tbody>
            </table>
            : <p>You have paid all your debts! 🎉</p>
        }

        <h4>Other Payments</h4>

        {othersPayments.length > 0 ?
            <div>
                <p>These are payments that other group members should make.</p>

                <table className="table">
                    <tbody>
                    <tr>
                        <th>From</th>
                        <th>To</th>
                        <th>Amount</th>
                        <th></th>
                    </tr>
                    {othersPayments.map((p, i) => {
                        return <tr key={i}>
                            <td>{getMember(p.from).name}</td>
                            <td>{getMember(p.to).name}</td>
                            <td>{p.amount} kr</td>
                            <td></td>
                        </tr>
                    }) }
                    </tbody>
                </table>
            </div>
            : <p>The rest of the group is all settled up! 🎉</p>
        }

        {group.suggestedPayments.length > 0 ?
        <div>
        <h4>Transfer</h4>

            <p>The debt from one member can be transfered to another. Useful if someone else should pick up the bill.</p>
                
            <button className="btn btn-outline-success btn-lg btn-block mt-3 mb-3" onClick={() => state.history.push("/groups/"+groupId+"/transfer-balance")}>Transfer Debt</button>
        </div>
        : null}

        <h4>Manual Payment</h4>

        <p>You can make any manual payment between group members and the balances will be tracked.</p>
                
        <button className="btn btn-outline-success btn-lg btn-block mt-3 mb-3" onClick={() => state.history.push("/groups/"+groupId+"/settle-manual")}>Manual Payment</button>
    </div>
}

export function GroupSettleManualLookup(props) {
    let groupId = props.match.params.groupId;
    useEffect(() => props.state.connection.send({type: "getGroup", groupId: groupId}), []);

    let group = props.state.groupStates[groupId];
    if (!group) {
        return <div>Loading...</div>
    }

    return <GroupSettleManual {...props} group={group}/>
}

export function GroupSettleManual(props) {
    let group = props.group;
    const paymentMode = props.mode === "payment";

    let ids = group.members.map(m => m.id);
    let myId = ids.filter(id => id === getUserId(props.state))[0] || ids[0] || null;
    let activeMembers = group.members.filter(m => m.state === "active" || m.balance !== 0);

    let [from, setFrom] = useState(myId);
    let [to, setTo] = useState(null);
    let [amount, setAmount] = useState(0);
    let [message, setMessage] = useState("");

    function valid() {
        return from && to && (from !== to) && (!paymentMode || amount > 1e-8);
    }

    function sendSettlement() {
        if (paymentMode) {
        props.state.connection.send({
            type: "addGroupSettlement",
            groupId: group.groupId,
            from,
            to,
            amount,
            paymentType: "unverified",
            message
        });
        props.history.push("/groups/"+group.groupId);
        } else {
            let amount = -group.members.filter(m => m.id === from)[0].balance;
            props.state.connection.send({
                type: "addGroupSettlement",
                groupId: group.groupId,
                from,
                to,
                amount,
                paymentType: "balance transfer",
                message
            });
            props.history.push("/groups/"+group.groupId);
        }
    }

    function buttonClass(value, selected) {
        if (value === selected) {
            return "btn-primary";
        } else {
            return "btn-secondary";
        }
    }

    let fromMembers = activeMembers;
    if (!paymentMode) {
        fromMembers = fromMembers.filter(m => m.balance < 0);
    }

    return <div>
        <Link to={"/groups/"+group.groupId+"/settle"}>&lt; Back</Link>

        {paymentMode ?
        <h4>Register Manual Payment</h4>
            : <h4>Transfer Debt</h4>
        }

        <div className="row">
            <div className="col-md-3">
                <div className="form-group">
                    <label>From: &nbsp; </label>
                    {fromMembers.map(m =>
                            <div key={m.id}>
                    <button className={"btn btn-small btn-block "+buttonClass(from, m.id)}
                            onClick={() => setFrom(m.id)}>
                                {m.name} {!paymentMode ? " ("+(-m.balance)+" kr)" : null}
                    </button>
                </div>
                    )}
                </div>
            </div>

            <div className="col-md-3">
                <div className="form-group">
                    <label>To: &nbsp; </label>
                    {activeMembers
                        .filter(m => m.id !== from)
                        .map(m =>
                                <div key={m.id}>
                    <button className={"btn btn-small btn-block "+buttonClass(to, m.id)}
                            onClick={() => setTo(m.id)}>{m.name}
                    </button>
                </div>
                        )}
                </div>
            </div>

            <div className="col-md-6">
                {paymentMode ?
                <div>
                    <div className="form-group">
                        <label>Amount: </label>
                        <FloatInput type="number" className="form-control" placeholder={"Amount"} value={amount}
                                    onChange={setAmount}/>
                    </div>
                </div>
                : null }

                <div>
                    <div className="form-group">
                        <label>Public comment: </label>
                        <input type="text" className="form-control" placeholder={"Comment"} value={message}
                               onChange={e => setMessage(e.target.value)}/>
                    </div>
                </div>
            </div>
        </div>

        <button className="btn btn-success btn-lg btn-block mb-2" disabled={!valid()} onClick={sendSettlement}>
        {paymentMode ?
            "Register Payment"
            : "Transfer Debt"
        }
        </button>
    </div>
}

export function TableGroupSet(props) {
    useEffect(() => props.state.connection.send({type: "listGroups"}), []);

    function setGroup(groupId) {
        props.state.connection.send({type: "setGroupForTable", tableId: props.tableId, groupId: groupId})
    }

    if (props.groupId) {
        return <table className="table table-striped">
            <tbody>
            <tr>
                <td>
                    <strong>{(props.state.groupList.filter(g => g.id === props.groupId)[0] || {}).name}</strong>
                </td>
                <td>
                    <button className="btn btn-danger float-right" onClick={() => setGroup(null)}>Remove!</button>
                </td>
            </tr>
            </tbody>
        </table>

    } else if (props.state.groupList) {
        return <table className="table table-striped">
            <tbody>
            {props.state.groupList
                .map(g =>
                    <tr key={g.id}>
                        <td>
                            <strong>{g.name}</strong>
                        </td>
                        <td>
                            <button className="btn btn-success float-right" onClick={() => setGroup(g.id)}>Add!</button>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    } else {
        return <div>No groups to add to</div>
    }
}