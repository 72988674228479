import React from 'react';
import './App.css';
import {Main} from './common/common_components'

function App(props) {
  return (
    <div className="App">

      <Main state={props.state}/>
    </div>
  );
}

export default App;
