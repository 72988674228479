import {addError} from "../common/common_reducer";
import {log, logError} from "../common/utils"
import {registerReducer, setInitialState} from "../common/redux_core";
import imageCompression from 'browser-image-compression';

setInitialState({
    tablePendingMessages: [],
    tablePendingImage: null,
    lastCreatedTable: null,
});

registerReducer("RECEIVED_CREATETABLE", function (state, action) {
    let tableId = action.msg.tableId;
    state.lastCreatedTable = tableId;

    if (state.tablePendingMessages.length > 0) {
        let msgs = state.tablePendingMessages[0];
        state.tablePendingMessages = state.tablePendingMessages.slice(1);

        for (let msg of msgs) {
            msg.tableId = tableId;
            state.connection.send(msg);
        }
    }

    if (state.tablePendingImage) {
        // Image specified in create bill should now upload
        state = uploadImage(state, tableId);
    } else {
        state.history.push("/bill/"+tableId);
    }

    return state;
});

registerReducer("QUEUE_TABLE_DATA", function (state, action) {
    state.tablePendingMessages.push(action.msgs);
});

registerReducer("GO_TO_TABLE", function (state, action) {
    if (state.lastCreatedTable) {
        state.history.push("/bill/"+state.lastCreatedTable);
        state.lastCreatedTable = null;
    }

    return state;
});

registerReducer("TABLE_SET_PENDING_IMAGE", function (state, action) {
    log("set pending image")
    state.tablePendingImage = action.image;

    return state;
});

registerReducer("TABLE_UPLOAD_IMAGE", function (state, action) {
    return uploadImage(state, action.tableId);
});

function uploadImage(state, tableId) {
    if (!tableId) {
        throw new Error("Table id required for image upload");
    }

    if (state.tablePendingImage) {
        let image = state.tablePendingImage;
        state.tablePendingImage = null;

        log("Fetching exif orientation");
        imageCompression.getExifOrientation(image).then(orientation => {
            const maxSizeMB = 1
            log("Compressing image to max", maxSizeMB, "MB");

            imageCompression(image, {
                maxSizeMB,
                maxWidthOrHeight: 1440,
                useWebWorker: true,
                exifOrientation: orientation,
            }).then((data) => {
                log("Resized result", data);
                sendImageToServer(state, tableId, data);
            }).catch(error => {
                log(error);
                let msg = "Error uploading image: " + error;
                state = addError(state, { error: msg });
            })
        }).catch(error => {
            log(error);
            let msg = "Error getting exif: " + error;
            state = addError(state, { error: msg });
        })
    } else {
        state = addError(state, {error: "No image found to upload!"});
        logError("Ignore image upload, no image found");
    }

    return state;
}

function sendImageToServer(state, tableId, image) {
    let host = state.connection.host.replace("ws", "http")+"/receipts?tableId="+tableId;
    log("Uploading image...", tableId, image, "to", host);

    fetch(host, {
        method: 'POST',
        headers: {
            "X-Fish": state.connection.token,
            "Content-Type": "application/octet-stream",
            "Table-Id": tableId
        },
        body: image
    }).then( response => {
        if (response.status < 300) {
            log("Image upload successful!", response);
        } else {
            logError("Error uploading image", response.statusText);
            state = addError(state, {error: "Error uploading image: "+response});
        }
    }).catch(error => {
        logError(error);
        let msg = "Error uploading image: " + error;
        state = addError(state, {error: msg});
        logError(msg);
        // logError("properties: "+listAllProperties(error).join(" "));
    });
}

function listAllProperties(o) {
	var objectToInspect;
	var result = [];

	for(objectToInspect = o; objectToInspect !== null;
           objectToInspect = Object.getPrototypeOf(objectToInspect)) {
        result = result.concat(
            Object.getOwnPropertyNames(objectToInspect)
        );
    }

    for (let r of result) {
        log(r + ":", o[r])
    }

	return result;
}
