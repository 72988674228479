import { registerReducer, setInitialState } from '../common/redux_core'

setInitialState({
    friends: [],
    tablesOngoing: [],
    tablesCompleted: [],
    tableStates: [],
    tableOCR: [],
});

registerReducer("RECEIVED_GETFRIENDS", function tableState(state, action) {
    state.friends = action.msg.friends;

    return state;
});

registerReducer("RECEIVED_TABLESONGOING", function tableState(state, action) {
    state.tablesOngoing = action.msg.tables;

    return state;
});

registerReducer("RECEIVED_TABLESCOMPLETED", function tableState(state, action) {
    state.tablesCompleted = action.msg.tables;

    return state;
});
