import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootswatch/dist/superhero/bootstrap.css'


// The subreducers need to be imported first to setup their shit
import { getStore } from './common/redux_store'

function render() {
    ReactDOM.render(
        <App state={getStore().getState()} />,
        document.getElementById('root')
    );
}

getStore().subscribe(() => render());
render(); // This is called after INIT event atm, so we have to trigger render manually

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

document.onmouseup = e => {
    if (e.target.type === 'submit' || e.target.type === 'button') {
        e.target.blur();
    }
};
