import _ from 'lodash'
import {ignoreReducer, registerReducer, setInitialState} from '../common/redux_core'
import {getStore} from "../common/redux_store";
import {haxxEnabled} from "../common/utils";
import {addError} from "../common/common_reducer";
import {removeTableState} from "../bill/bill_reducer";

// Connection state machine
export let CONNECTION_STATE = {
    // "loggedOut" - The user is not logged in.
    loggedOut: "loggedOut",
    // "authing" - The user has provided an email for login, and a temporary connection is made to check it.
    authing: "authing",
    // "registering" - The user has temporarily connected to create a profile. Similar to authing.
    registering: "registering",

    // "connecting" - Trying to auth using saved or provided credentials, or trying waiting to connect for register
    connecting: "connecting",
    // "connected" - Authed and connected
    connected: "connected",
    // "disconnected" - Was authed, but was disconnected, trying to reconnect. Can still view saved state in the app.
    disconnected: "disconnected",
};

let initialState = localStorage.getItem("connectionState") || "loggedOut";

export function isLoggedIn(state) {
    let connState = state.connection.state;
    return connState === CONNECTION_STATE.connected || connState === CONNECTION_STATE.disconnected;
}

    /**
 *
       +----------------------------------------+
       |                                        |
       |                                        v
 +-----+------+     +---------+         +-------+------+
 |            |     |         |         |              |
 | Logged Out +-----> Authing | +-------+ Connecting   |
 |            |     |         | |       |              |
 +------------+     +----+----+ |       +--------------+
                         |      |
                         |      v
                    +----v------+       +--------------+
                    |           +------>+              |
                    | Connected |       | Disconnected |
                    |           +<------+              |
                    +-----------+       +--------------+

 The states "loggedOut" "authing" and "connecting" are persisted across logins.
 The user can always transition to the logout state.
 */

setInitialState({
    connection: {
        sendQueue: [],
        state: initialState
    },
    loggingIn: false,
    userState: {},
});

function setConnectionState(state, connectionState) {
    console.log("Connection state changed to "+connectionState);

    state.connection.state = connectionState;
    let startupState = {
        [CONNECTION_STATE.loggedOut]: CONNECTION_STATE.loggedOut,
        [CONNECTION_STATE.registering]: CONNECTION_STATE.loggedOut,
        [CONNECTION_STATE.authing]: CONNECTION_STATE.loggedOut,
        [CONNECTION_STATE.connecting]: CONNECTION_STATE.connecting,
        [CONNECTION_STATE.connected]: CONNECTION_STATE.connecting,
        [CONNECTION_STATE.disconnected]: CONNECTION_STATE.connecting,
    }[connectionState];
    if (startupState) {
        localStorage.setItem("connectionState", startupState);
    }
}

function autoConnectOnStartup(state) {
    if (state.connection.state === CONNECTION_STATE.connecting) {
        console.log("Executing auto-connect with last used credentials");
        
        state = connect(state, {
            host: localStorage.getItem("lastUsedHost"),
            token: localStorage.getItem("lastUsedToken"),
        });
        return state;
    }
}

registerReducer("@@redux/INIT", function(state, action) {
    autoConnectOnStartup(state);

    return state;
});

function connect(state, action) {
    // NOTE this reducer has side effects, it sets up a connection to the server.
    // This is probably desirable when replaying app state, so we allow it for now

    if (state.connection.state === CONNECTION_STATE.loggedOut) {
        setConnectionState(state, CONNECTION_STATE.connecting);
    }
    state.connection.host = action.host;
    state.connection.token = action.token;

    let messageCallback = function(event) {
        try {
            let msg = JSON.parse(event.data);

            if (msg.type !== "ping") {
                state.lastMessage = msg;
            }

            if (msg && (msg.status !== "fail" && msg.status !== "error") && msg.type) {
                getStore().dispatch({type: "RECEIVED_"+msg.type.toUpperCase(), msg: msg});
            } else {
                console.error("Message error:", event);
                console.error("Message:", msg);
                getStore().dispatch({type: "ADD_ERROR", error: msg.message});
                
                if (msg && msg.type) {
                    getStore().dispatch({type: "RECEIVED_"+msg.type.toUpperCase()+"_FAILURE", msg: msg});
                }
            }
        } catch (e) {
            getStore().dispatch({type: "ADD_ERROR", error: e});
        }
    };
    
    connectWithAutoReconnect(state.connection, messageCallback);

    state.connection.send = function send(msg) {
        if (state.connection.state === CONNECTION_STATE.connected) {
            state.connection.executeSend(msg);
        } else {
            console.log("Connection not open. Putting msg on queue ", msg);
            state.connection.sendQueue.push(msg);
        }
    };

    state.connection.executeSend = function send(msg) {
        try {
            console.log("Sending message:", msg);
            if (!_.isString(msg)) {
                msg = JSON.stringify(msg);
            }
            state.connection.socket.send(msg);
        } catch (e) {
            console.error(e);
            getStore().dispatch({type: "ADD_ERROR", error: e});
        }
    };
    
    localStorage.setItem("lastUsedToken", state.connection.token);
    localStorage.setItem("lastUsedHost", state.connection.host);

    // Allow sending from console
    window.send = state.connection.send;

    return state;
};

registerReducer("CONNECT", connect);
ignoreReducer("RECEIVED_PING");

function connectWithAutoReconnect(connection, messageCallback) {
    let connectUrl = connection.host + "/api/v3" + (connection.token ? "/"+connection.token : "");
    
    console.log("Connecting to "+connectUrl);
    connection.lastAttempt = new Date().getTime();
    connection.reconnectTimeout = null;

    function reconnect(event) {
        console.log("Disconnected", event);
        socket.onmessage = null;

        getStore().dispatch({type: "RECONNECTING"});

        reconnectWithMinWait(connection, messageCallback);
    }
    
    let socket = new WebSocket(connectUrl);
    socket.onmessage = messageCallback;
    socket.onerror = reconnect;
    socket.onopen = function() {
        socket.onclose = reconnect;

        getStore().dispatch({type: "CONNECTION_OPEN"});
    };

    connection.socket = socket;
}

registerReducer("LOGIN_EMAIL", function link(state, action) {
    setConnectionState(state, CONNECTION_STATE.authing);
    state = connect(state, {host: action.host, token: null});
    state.connection.send({
        type: "loginWithPassword",
        email: action.email,
        password: action.password
    });

    return state;
});

registerReducer("RECEIVED_LOGINWITHPASSWORD_FAILURE", logOut);

registerReducer("RECEIVED_LOGINWITHPASSWORD", function login_success(state, action) {
    setConnectionState(state, CONNECTION_STATE.connected);
    window.location = "/";

    return state;
});

registerReducer("REGISTER_ACCOUNT", function link(state, action) {
    setConnectionState(state, CONNECTION_STATE.registering);

    state = connect(state, {host: action.host, token: null});
    state.connection.send({
        type: "changePublicName",
        publicName: action.publicName,
    });

    if (action.email) {
        state.connection.send({
            type: "registerEmail",
            email: action.email,
        });
    }

    if (action.phone) {
        state.connection.send({
            type: "changePhone",
            phone: action.phone,
        });
    }

    if (action.password) {
        state.connection.send({
            type: "initPassword",
            newPassword: action.password,
        });
    }

    return state;
});

registerReducer("RECEIVED_REGISTEREMAIL_FAILURE", function(state, action) {
    logOut(state, action);
});

registerReducer("RECEIVED_INITPASSWORD", function(state, action) {
    // Close temporary connection and wait for the user to verify and log in
    logOut(state, action);
    window.location = "/registered"
});

registerReducer("SEND_RESET_PASSWORD", function sendReset(state, action) {
    setConnectionState(state, CONNECTION_STATE.registering);

    state = connect(state, {host: action.host, token: null});
    state.connection.send({
        type: "sendPasswordResetEmail",
        email: action.email,
        app: "webclient"
    });

    return state;
});

registerReducer("RECEIVED_SENDPASSWORDRESETEMAIL", function(state, action) {
    state.messages.push({msg: "Check your inbox for a reset email", type: "success"});
    logOut(state, action);

    return state;
});

registerReducer("RESET_PASSWORD", function sendReset(state, action) {
    setConnectionState(state, CONNECTION_STATE.registering);

    state = connect(state, {host: action.host, token: null});
    state.connection.send({
        type: "resetPassword",
        token: action.token,
        newPassword: action.password
    });

    return state;
});

registerReducer("RECEIVED_RESETPASSWORD", function(state, action) {
    state.messages.push({msg: "Password reset, you can now log in!", type: "success"});
    logOut(state, action);

    return state;
});

registerReducer("CONNECTION_OPEN", function becameConnected(state, action) {
    if (state.connection.token) {
        setConnectionState(state, CONNECTION_STATE.connected);
    }

    while (state.connection.sendQueue.length) {
        state.connection.executeSend(state.connection.sendQueue.shift());
    }
    
    return state;
});

registerReducer("RECONNECTING", function becameConnected(state, action) {
    if (state.connection.state === CONNECTION_STATE.connected) {
        setConnectionState(state, CONNECTION_STATE.disconnected);
    }

    return state;
});

function reconnectWithMinWait(connection, messageCallback) {
    let connect = function() {
        connection.reconnectTimeout = null;
        connectWithAutoReconnect(connection, messageCallback)
    };

    let minWaitMs = 10 * 1000;
    let timeSinceLastConnect = new Date().getTime() - connection.lastAttempt;
    if (timeSinceLastConnect < minWaitMs) {
        let timeout = minWaitMs - timeSinceLastConnect;
        console.log("Waiting for "+minWaitMs+ " ms before reconnect");
        connection.reconnectTimeout = setTimeout(connect, timeout);
    } else {
        connect();
    }
}

function logOut(state, action) {
    console.log("Logging out");

    // Make sure no reconnection is made for current socket
    state.loggingIn = false;
    state.connection.socket.onclose = null;
    state.connection.socket.onmessage = null;
    if (state.connection.reconnectTimeout) {
        clearTimeout(state.connection.reconnectTimeout);
    }
    state.connection.socket.close();

    setConnectionState(state, CONNECTION_STATE.loggedOut);
    localStorage.setItem("lastUsedToken", null);

    removeTableState(state);

    return state;
};

registerReducer("LOG_OUT", logOut);

registerReducer("RECEIVED_USERSTATE", function authenticationError(state, action) {
    let msg = action.msg;
    let token = msg.token;

    if (state.connection.token && state.connection.token !== token) {
        state = addError(state, {error: "Token is not authenticated!"});
        state = logOut(state);
        return state;
    }

    // Do not save tokens when getting them first on registration
    if (state.connection.state == CONNECTION_STATE.registering) {
        return state
    }

    if (!state.connection.token) {
        state.connection.token = action.msg.token;
        localStorage.setItem("lastUsedToken", state.connection.token);

        // This check is to prevent "authing" -> "connected"
        // Authing state still needs lastUsedToken to be defined
        if (state.connection.state === CONNECTION_STATE.connecting) {
            setConnectionState(state, CONNECTION_STATE.connected);
        }
    }

    state.userState = action.msg;

    if (haxxEnabled()) {
        saveToken(state, {name: msg.publicName || "Unknown", email: msg.email, phone: msg.phone})
    }

    return state;
});

function saveToken(state, me) {
    updateCache("cachedTokens", function(cache) {
        let id = me.email || me.name;
        if (state.connection.token) {
            console.log("saving token!!!");
            cache[state.connection.host + "-" + id] = {email: me.email, name: me.name, phone: me.phone, token: state.connection.token, host: state.connection.host}
        }
    });
}

function assignCache(cacheKey, update) {
    updateCache(cacheKey, cache => _.map(update, (v,k) => cache[k] = _.assign(cache[k], v)));
}

function updateCache(cacheKey, callback) {
    var jsonData = localStorage.getItem(cacheKey);
    localStorage.removeItem(cacheKey); // Defensive anti-corruption
    var cache = JSON.parse(jsonData) || {};

    callback(cache);

    localStorage.setItem(cacheKey, JSON.stringify(cache));
}

registerReducer("REMOVE_TOKEN", function removeToken(state, action) {
    updateCache("cachedTokens", function(cache) {
        delete cache[action.key];
    });

    return state;
});

export { assignCache, saveToken }
