let reducers = {};
var initialState = {};

function registerReducer(reducerName, func) {
    if (reducers[reducerName]) {
        throw "Reducer "+reducerName+" already registered!";
    }

    reducers[reducerName] = func;
}

// Utility function to not get errors in the log
function ignoreReducer(reducerName) {
    registerReducer(reducerName, (state) => state);
}

function setInitialState(partialInitialState) {
    Object.assign(initialState, partialInitialState);
}

function reducer(state, action) {
    // TODO you are doing everything wrong - fix with immutable.js
    state = state || initialState;

    window.state = state;

    let type = action.type;
    if (type.startsWith("@@redux/INIT")) {
        // Strip random suffix added in newer redux
        type = "@@redux/INIT";
    }
    console.log("Reducing action "+type, action);

    try {
        let actionReducer = reducers[type];

        if (actionReducer) {
            let ret = actionReducer(state, action);

            if (ret) {
                return ret;
            }

            console.error("Reducer returned null, this is not valid state!");
        } else {
            console.error("Unknown action "+type+", state will not change!");
        }

        return state;
    } catch(e) {
        console.error("ERROR in reducer", e);
        return callbacks.addError(state, {error: e.toString()});
    }
};

let callbacks = {};

// Yup this is hacky. Legacy from the old setup.
function setAddError(fn) {
    callbacks.addError = fn;
}

export { registerReducer, ignoreReducer, setInitialState, reducer, initialState, setAddError};
