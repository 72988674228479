import React, {useEffect, useState} from 'react';
import _ from 'lodash'
import {AddMembers, groupPrefix} from "./bill_create_components";
import {getStore} from "../common/redux_store";
import {CurrencySelect, FloatInput} from "../common/common_components";
import {CONNECTION_STATE} from "../connection/connection_reducer";

export function ImageCreateStart(props) {
    function upload() {
        props.state.connection.send({type: "createTable"});
        props.state.history.push(groupPrefix(props) + "/create/image")
    }

    return <div>
        <TableImageUpload onComplete={upload}/>
    </div>
}

export function ImageCreateDetails(props) {
    let tableId = props.state.lastCreatedTable;

    const [currency, setCurrency] = useState("SEK");
    const [tip, setTip] = useState(0);
    const [members, setMembers] = useState([]);

    let groupId = props.groupId || _.get(props, "match.params.groupId");
    let group = props.state.groupStates[groupId] || {};
    let memberCandidates = [];
    if (group.members) {
        memberCandidates = group.members
            .filter(m => !m.state || m.state === 'active')
            .filter(m => m.id !== _.get(props, 'state.userState.publicUserId'));
    } else {
        memberCandidates = props.state.friends;
    }

    useEffect(() => {
        if (groupId) {
            props.state.connection.send({type: "getGroup", groupId});
        } else {
            props.state.connection.send({type: "getFriends"});
        }
    }, []);

    function done() {
        props.state.connection.send({type: "setCurrencyForTable", tableId, currency: currency});
        if (tip > 0) {
            props.state.connection.send({type: "setTipForTable", tableId, amount: tip});
        }
        for (let m of members) {
            props.state.connection.send({type: "addPersonToTable", tableId, id: m.id});
        }
        if (groupId) {
            props.state.connection.send({type: "setGroupForTable", tableId, groupId});
        }

        if (props.state.connection.state === CONNECTION_STATE.connected) {
            props.state.history.push("/bill/"+tableId)
        } else {
            props.state.history.push("/");
        }
    }

    if (!tableId) {
    }

    return <div>
        <AddMembers members={members} setMembers={setMembers} allMembers={memberCandidates}/>

        <small>More members can be added using the invite code for the table.</small>

        <CurrencySelect setValue={setCurrency}/>

        <div className="mb-4 form-group">
            <label>Extra tip: </label>
            {/* Note: the ""+amount is needed to strip a leading 0*/}
            <FloatInput type="number" className="form-control" placeholder={"Extra tip"} value={tip} onChange={setTip}/>
        </div>

        {!tableId ?
            <h2>Waiting for table to create...</h2>
            : <button type="submit" className="btn btn-success btn-lg btn-block mb-2" onClick={done}
                disabled={tableId === null}>Go to table</button>}
    </div>
}

export class TableImageUpload extends React.Component{
    setImage(e) {
        let file = e.target.files[0];

        if (!file || !file.type.match(/image.*/)) {
            getStore().dispatch({type: "ADD_ERROR", error: "File is not an image!"});
            return;
        }

        getStore().dispatch({type: "TABLE_SET_PENDING_IMAGE", image: file});

        if (this.props.autoUpload) {
            getStore().dispatch({type: "TABLE_UPLOAD_IMAGE", tableId: this.props.tableId});
        }

        if (this.props.onComplete) {
            this.props.onComplete();
        }
    }
    render() {
        let title = this.props.title || "Scan Bill Photo";
        return (
            <label className="btn btn-primary btn-lg btn-block mb-2">
                <span>📸</span> {title}
                <input type="file" className="form-control" onChange={this.setImage.bind(this)} hidden/>
            </label>
        )
    }
}

