import {registerReducer, setAddError, setInitialState} from './redux_core'

setInitialState({
    messages: [],
    currentAuth: null
});

function addMessage(state, message) {
    state.messages.push(message);
    return state;
}

registerReducer("REMOVE_MESSAGE", function removeMessage(state, action) {
    state.messages.splice(action.index, 1);
    return state;
});

function addError(state, action) {
    return addMessage(state, {msg: (action.error || "Unknown error (null)").toString(), type: "error"});
}
registerReducer("ADD_ERROR", addError);
setAddError(addError);

function addSuccess(state, action) {
    return addMessage(state, {msg: action.msg.toString(), type: "success"});
}
registerReducer("ADD_SUCCESS", addSuccess);

export {addError, addSuccess}
