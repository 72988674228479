import {ignoreReducer, registerReducer, setInitialState} from '../common/redux_core'

setInitialState({
    tableStates: [],
    tableOCR: [],
    defaultEditMode: "claim",
});

registerReducer("SET_DEFAULT_EDIT_MODE", function tableState(state, action) {
    state.defaultEditMode = action.defaultEditMode;
    return state;
});

registerReducer("RECEIVED_VIEWTABLE", function tableState(state, action) {
    if (action.msg.tableId) {
        state.currentTableId = action.msg.tableId;
    }
    return state;
});

registerReducer("RECEIVED_TABLESTATE", function tableState(state, action) {
    let table = action.msg;
    state.tableStates[table.tableId] = table;

    return state;
});

registerReducer("RECEIVED_GETRECEIPTOCR", function tableState(state, action) {
    let ocr = action.msg;
    state.tableOCR[ocr.tableId] = ocr;

    return state;
});

ignoreReducer("RECEIVED_SETTOTAL");
ignoreReducer("RECEIVED_SETNAME");
ignoreReducer("RECEIVED_SETRECEIPTENTRYCLAIMS");
ignoreReducer("RECEIVED_SETRECEIPTENTRYSHARES");

registerReducer("RECEIVED_ADDITEMTOTABLE", function removedFromTable(state, action) {
    // Nothing to do here, tablestate will update

    return state;
});

function removeTableState(state) {
    state.tableStates[state.currentTableId] = null;
    state.currentTableId = null;
    state.tableOngoingPayment = false;
    if (state.history) {
        state.history.push("/");
    }
}

registerReducer("RECEIVED_REMOVEDFROMTABLE", function removedFromTable(state, action) {
    removeTableState(state);

    return state;
});

registerReducer("RECEIVED_LEAVETABLE", function leftTable(state, action) {
    removeTableState(state);

    return state;
});

registerReducer("RECEIVED_LEAVETABLE_FAILURE", function leftTable(state, action) {
    removeTableState(state);

    return state;
});

export {removeTableState}
