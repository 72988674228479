// Snodd från webtable

class Swish {
  static getSwishUrl(payee, amount, message, callbackUrl = null) {
    if (payee.startsWith("46")) {
      payee = "0" + payee.slice(2);
    }

    var swishObj = {
      payee: {
        value: payee,
        editable: false
      },
      amount: {
        value: amount,
        editable: false
      },
      message: {
        value: encodeURIComponent(message),
        editable: false
      },
      version: 1
    };

    // const url = window.location.protocol+'//'+window.location.hostname +(window.location.port ? ':'+window.location.port: '');
    const url = callbackUrl || window.location.href;


    return "swish://payment?data=" + JSON.stringify(swishObj)
      + "&callbackurl=" + url + "&callbackresultparameter=res";
  }
}

export default Swish;