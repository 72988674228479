import React, {useState} from 'react';
import {haxxEnabled} from "../common/utils";
import {getStore} from "../common/redux_store";
import {Link} from "react-router-dom";

export function Register(props) {
    let defaultHost = (haxxEnabled() && localStorage.getItem("lastUsedHost")) || "wss://api.use.fish";
    let [host, setHost] = useState(defaultHost);
    let [name, setName] = useState("");
    let [phone, setPhone] = useState("");
    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");

    function register() {
        getStore().dispatch({
            type: "REGISTER_ACCOUNT",
            host,
            publicName: name,
            email,
            phone,
            password
        });
    }

    let registerEnabled = (name && phone && email && password);

    return <div>
        <h2>Register</h2>

        <div>
            <Link to="/">Back</Link>
        </div>

        <div>
            {haxxEnabled() ?
                <div className="form-group">
                    <label htmlFor="backendHostInput">Backend host: </label>
                    <input type="text" className="form-control" value={host} onChange={e => setHost(e.target.value)}/>
                </div>
                : null}

            <div className="form-group">
                <label htmlFor="deviceName">Your full name (will be visible to other users): </label>
                <input type="text" className="form-control" value={name} onChange={e => setName(e.target.value)}/>
            </div>

            <div className="form-group">
                <label htmlFor="tokenInput">Email (Used to login): </label>
                <input type="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)}/>
            </div>

            <div className="form-group">
                <label htmlFor="tokenInput">Password: </label>
                <input type="password" className="form-control" value={password} onChange={e => setPassword(e.target.value)}/>
            </div>

            <div className="form-group">
                <label htmlFor="tokenInput">Phone Nr (Your friends will send Swish-payments to this number): </label>
                <input type="text" className="form-control" value={phone} onChange={e => setPhone(e.target.value)}/>
            </div>

            <button className="btn btn-default btn-block btn-success" disabled={!registerEnabled}
                    onClick={register}>Create account</button>
        </div>
    </div>
}

export function Registered(props) {
    return <div>
        <h2>Next steps</h2>

        <ol>
            <li>Open your email and click the verify link. This must be done before login.</li>
            <li>Return to the <a href="/">login page</a> and login with your password.</li>
        </ol>
    </div>
}