import React, {useState} from 'react';
import {haxxEnabled} from "../common/utils";
import {getStore} from "../common/redux_store";
import {Link} from "react-router-dom";

export function SendReset(props) {
    let defaultHost = (haxxEnabled() && localStorage.getItem("lastUsedHost")) || "wss://api.use.fish";
    let [host, setHost] = useState(defaultHost);
    let [email, setEmail] = useState("");

    function sendReset() {
        getStore().dispatch({
            type: "SEND_RESET_PASSWORD",
            host,
            email,
        });
    }

    let buttonEnabled = (email);

    return <div>
        <h2>Reset Password</h2>

        <div>
            <Link to="/">Back</Link>
        </div>

        <div>
            {haxxEnabled() ?
                <div className="form-group">
                    <label htmlFor="backendHostInput">Backend host: </label>
                    <input type="text" className="form-control" value={host} onChange={e => setHost(e.target.value)}/>
                </div>
                : null}

            <div className="form-group">
                <label htmlFor="tokenInput">Email: </label>
                <input type="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)}/>
            </div>

            <button className="btn btn-default btn-block btn-success" disabled={!buttonEnabled}
                    onClick={sendReset}>Send Reset Link</button>
        </div>
    </div>
}


export function Reset(props) {
    let authToken = window.location.hash.replace("#resetToken=", "")

    let defaultHost = (haxxEnabled() && localStorage.getItem("lastUsedHost")) || "wss://api.use.fish";
    let [host, setHost] = useState(defaultHost);
    let [token, setToken] = useState(authToken);
    let [password, setPassword] = useState("");

    function reset() {
        getStore().dispatch({
            type: "RESET_PASSWORD",
            host,
            token,
            password
        });
    }

    let buttonEnabled = (token && password);

    return <div>
        <h2>Reset Password</h2>

        <div>
            <Link to="/">Back</Link>
        </div>

        <div>
            {haxxEnabled() ?
                <div className="form-group">
                    <label htmlFor="backendHostInput">Backend host: </label>
                    <input type="text" className="form-control" value={host} onChange={e => setHost(e.target.value)}/>
                </div>
                : null}

            <div className="form-group">
                <label htmlFor="tokenInput">Reset token: </label>
                <input type="email" className="form-control" value={token} onChange={e => setToken(e.target.value)}/>
            </div>

            <div className="form-group">
                <label htmlFor="tokenInput">Password: </label>
                <input type="password" className="form-control" value={password} onChange={e => setPassword(e.target.value)}/>
            </div>

            <button className="btn btn-default btn-block btn-success" disabled={!buttonEnabled}
                    onClick={reset}>Reset Password</button>
        </div>
    </div>
}