import {ignoreReducer, registerReducer, setInitialState} from "../common/redux_core";

setInitialState({
    groupList: [],
    groupStates: {},
});

registerReducer("RECEIVED_CREATEGROUP", function tableState(state, action) {
    state.history.push("/groups/"+action.msg.groupId);
    return state;
});

registerReducer("RECEIVED_LISTGROUPS", function tableState(state, action) {
    state.groupList = action.msg.groups;
    return state;
});

registerReducer("RECEIVED_GETGROUP", function tableState(state, action) {
    state.groupStates[action.msg.groupId] = action.msg;
    return state;
});

ignoreReducer("RECEIVED_SETGROUPFORTABLE");
ignoreReducer("RECEIVED_ADDMEMBERTOGROUP");
