import { createStore } from 'redux'
import {initialState, reducer} from "./redux_core";

// Import these to set up the store reducers
import '../common/common_reducer'
import '../connection/connection_reducer'
import '../group/group_reducer'
import '../bill/bill_reducer'
import '../bill_create/bill_create_reducer'
import '../bill_list/bill_list_reducer'

let storeHolder = {
    store: null,
};

function getStore() {
    if (!storeHolder.store) {
        console.log("creating store");
        storeHolder.store = createStore(reducer, initialState);


        storeHolder.store.subscribe(function() {
            console.log("New state ", storeHolder.store.getState())
        });
    }
    return storeHolder.store;
}

export { getStore }
