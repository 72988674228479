import React, {useEffect, useState} from 'react';
import _ from 'lodash'
import {AddMembers, groupPrefix} from "./bill_create_components";
import {getStore} from "../common/redux_store";
import {CurrencySelect} from "../common/common_components";
import {FloatInput} from "../common/common_components";

function CreateQuick(props) {
    const mode = props.mode;
    const [name, setName] = useState("");
    const [amount, setAmount] = useState(0);
    const [currency, setCurrency] = useState("SEK");
    const [members, setMembers] = useState([]);
    const [items, setItems] = useState([]);
    const valid = name.length > 0 && amount > 0 && (mode === "items" ? items.length > 0 : true)

    let groupId = props.groupId || _.get(props, "match.params.groupId");
    let group = props.state.groupStates[groupId] || {};
    let memberCandidates = [];
    if (group.members) {
        memberCandidates = group.members
            .filter(m => !m.state || m.state === 'active')
            .filter(m => m.id !== _.get(props, 'state.userState.publicUserId'));
    } else {
        memberCandidates = props.state.friends;
    }

    useEffect(() => {
        if (groupId) {
            props.state.connection.send({type: "getGroup", groupId});
        } else {
            props.state.connection.send({type: "getFriends"});
        }
    }, []);

    function add(e) {
        // TODO it is a litte hacky that we do not have table id or entry id here
        // We abuse the fact that everything is processed synchronously
        let tableMsgs = [];
        tableMsgs.push({type: "setNameOfTable", name: name});
        tableMsgs.push({type: "setTotalForTable", totalAmount: amount, currency});
        for (let m of members) {
            tableMsgs.push({type: "addPersonToTable", id: m.id});
        }
        if (groupId) {
            tableMsgs.push({type: "setGroupForTable", groupId});
        }

        if (mode === "one") {
            tableMsgs.push({type: "createReceiptEntry", description: name, amount: amount});
            const firstId = 1000000000;
            tableMsgs.push({type: "claimReceiptEntry", entryId: firstId});
            for (let m of members) {
                tableMsgs.push({type: "setReceiptEntryClaims", userId: m.id, claims: 1, entryId: firstId});
            }
        } else if (mode === "items") {
            getStore().dispatch({type: "SET_DEFAULT_EDIT_MODE", defaultEditMode: "assign"});
            for (let it of items) {
                tableMsgs.push({type: "createReceiptEntry", description: it.name, amount: it.amount});
            }
        } else {
            throw new Error("Unsupported mode");
        }

        props.state.connection.send({type: "createTable"});
        getStore().dispatch({type: "QUEUE_TABLE_DATA", msgs: tableMsgs});

        if (!props.state.isConnected) {
            props.state.history.push("/");
        }

        e.preventDefault();
    }

    return <div>
        <form onSubmit={add}>
            <div className="form-group">
                <label>Name: </label>
                <input type="text" className="form-control" placeholder={"Name"} value={name}
                       onChange={e => setName(e.target.value)}/>
            </div>

            {
                mode === "items" ?
                    <div className="mb-4">
                        <label>Items: </label>

                        <ItemAdd items={items} setItems={setItems} setAmount={setAmount}/>

                        <h4 className="mt-4">Total Amount: {amount}</h4>
                    </div>
                    :
                    <div className="mb-4 form-group">
                        <label>Amount: </label>
                        {/* Note: the ""+amount is needed to strip a leading 0*/}
                        <FloatInput type="number" className="form-control" placeholder={"Amount"} value={amount} onChange={setAmount}/>
                    </div>
            }

            <CurrencySelect setValue={setCurrency}/>

            <AddMembers members={members} setMembers={setMembers} allMembers={memberCandidates}/>

            <button type="submit" className="btn btn-success btn-lg btn-block mb-2" onClick={add} disabled={!valid}>Create Bill</button>
        </form>
    </div>
}

function ItemAdd(props) {
    let items = props.items;
    let setItems = props.setItems;
    let setTotalAmount = props.setAmount;

    function setName(index, name) {
        items = items.slice();
        if (index >= items.length) {
            items = items.concat({name: name, amount: 0})
        } else {
            items[index].name = name;
        }
        setItems(items);
    }

    function setAmount(index, amount) {
        items = items.slice();
        items[index].amount = amount;
        setItems(items);
        setTotalAmount(_.sumBy(items, 'amount'))
    }

    function del(index, e) {
        items = items.slice();
        items.splice(index, 1);
        setItems(items);
        setTotalAmount(_.sumBy(items, 'amount'))
        e.preventDefault();
    }

    return <div>
        {[...Array(items.length + 1).keys()].map(i =>
            <div key={i} className="form-row mb-2">
                <div className="col-6 col-md-8">
                    <input type="text" className="form-control" placeholder={"Item name"}
                           value={(items[i] || {name: ""}).name} onChange={e => setName(i, e.target.value)}/>
                </div>
                <div className="col-3">
                    {i < items.length ?
                        <FloatInput type="number" className="form-control" placeholder={"Amount"}
                               value={items[i].amount} onChange={v => setAmount(i, v)}/>
                        : null}
                </div>
                <div className="col-3 col-md-1">
                    {i < items.length ?
                        <button className="btn btn-danger" onClick={(e) => del(i, e)} tabIndex="-1">Delete</button>
                        : null}
                </div>
            </div>
        )}
    </div>
}

export {CreateQuick}