import React, {useState} from 'react';
import Autosuggest from 'react-autosuggest';
import _ from 'lodash'
import {getStore} from "./redux_store";
import {BrowserRouter, Route, Switch} from "react-router-dom";

import {Connecting, Unauthed} from "../connection/connection_components";
import {TableView} from "../bill/bill_components";
import {TableList} from "../bill_list/bill_list_components";
import {haxxEnabled, registerLogSetState} from "../common/utils";
import {linkStyle} from "./common_components_util";
import {BillCreate} from "../bill_create/bill_create_components";
import {CreateQuick} from "../bill_create/bill_create_quick";
import {Settings} from "../settings/settings";
import {ImageCreateDetails} from "../bill_create/bill_create_wizard";
import {
    GroupCreate,
    GroupEditLookup,
    GroupList,
    GroupSettleLookup,
    GroupSettleManualLookup,
    GroupView
} from "../group/group_components";
import {CONNECTION_STATE, isLoggedIn} from "../connection/connection_reducer";

function LoggedIn(props) {
    let state = props.state;

    return <BrowserRouter>
        <div>
            <Switch>
                <Route exact path="/bill/:tableId" render={(props) => <TableView {...props} state={state}/>} />

                <Route exact path="/create" render={(props) => <BillCreate {...props} state={state}/>} />
                <Route exact path="/create/image" render={(props) => <ImageCreateDetails {...props} state={state}/>} />
                <Route exact path="/create/quick/one" render={(props) => <CreateQuick mode="one" {...props} state={state}/>} />
                <Route exact path="/create/quick/items" render={(props) => <CreateQuick mode="items" {...props} state={state}/>} />

                <Route exact path="/groups/create" render={(props) => <GroupCreate {...props} state={state}/>} />
                <Route exact path="/groups/:groupId/create" render={(props) => <BillCreate {...props} state={state}/>} />
                <Route exact path="/groups/:groupId/create/image" render={(props) => <ImageCreateDetails {...props} state={state}/>} />
                <Route exact path="/groups/:groupId/create/quick/one" render={(props) => <CreateQuick mode="one" {...props} state={state}/>} />
                <Route exact path="/groups/:groupId/create/quick/items" render={(props) => <CreateQuick mode="items" {...props} state={state}/>} />

                <Route exact path="/settings" render={(props) => <Settings {...props} state={state}/>} />

                <Route exact path="/bills" render={(props) => <TableList {...props} state={state} connection={state.connection}/>} />

                <Route exact path="/groups/:groupId/settle" render={(props) => <GroupSettleLookup {...props} state={state}/>} />
                <Route exact path="/groups/:groupId/settle-manual" render={(props) => <GroupSettleManualLookup {...props} state={state} mode="payment"/>} />
                <Route exact path="/groups/:groupId/transfer-balance" render={(props) => <GroupSettleManualLookup {...props} state={state} mode="transfer"/>} />
                <Route exact path="/groups/:groupId/edit" render={(props) => <GroupEditLookup {...props} state={state}/>} />
                <Route exact path="/groups/:groupId" render={(props) => <GroupView {...props} state={state}/>} />

                <Route exact render={(props) => <GroupList {...props} state={state}/>} />
            </Switch>
            <Route path="/" render={(props) => <StealHistory {...props} state={state}/>}/>
        </div>
    </BrowserRouter>
}

function StealHistory(props) {
    // HACK! Save history to redux scope for access. (why is this so hard and undocumented?)
    props.state.history = props.history;

    // This is not rendered
    return null;
}

function NavBar(props) {
    function link(to, e) {
        props.state.history.push(to);
        e.preventDefault();
    }

    return <nav className="navbar navbar-expand navbar-dark bg-primary">
        <a className="navbar-brand" href="/" onClick={e => link("/", e)}>Fish</a>

        <div className="navbar-collapse collapse" id="navbarColor01">
            <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                    <a className="nav-link" href="/" onClick={e => link("/", e)}>Groups</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="/bills" onClick={e => link("/bills", e)}>All Bills</a>
                </li>
            </ul>
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" href="/settings" onClick={e => link("/settings", e)}>Settings</a>
                </li>
            </ul>
        </div>
    </nav>
}

class Main extends React.Component {
    render() {
        let state = this.props.state;
        let connState = state.connection.state;
        let content = null;


        if (connState === CONNECTION_STATE.loggedOut || connState == CONNECTION_STATE.authing || connState == CONNECTION_STATE.registering) {
            content = <Unauthed connection={state.connection}/>
        } else if (connState === CONNECTION_STATE.connecting) {
            content = <Connecting/>
        } else if (isLoggedIn(state)) {
            content = <div>
                <LoggedIn state={state}/>
            </div>
        } else {
            console.error("Invalid connection state", connState)
            localStorage.removeItem("connectionState");
            // setTimeout(() => window.location.reload(), 3000);
        }

        return (
            <div>
                {isLoggedIn(state) ? <NavBar state={state}/> : null}

                <div className="container">
                    <div className="mt-2">
                        {connState === CONNECTION_STATE.disconnected ?
                            <div className="alert alert-light">Reconnecting...</div>
                            : null}

                        <Messages messages={state.messages}/>

                        {content}
                    </div>

                    {haxxEnabled() ?
                        <HaxxLogComponent/>
                        : null}
                </div>
            </div>
        )
    }
}

// This is a hack to get a log on mobile
function HaxxLogComponent(props) {
    let [log, setLog] = useState([])

    registerLogSetState(setLog);

    return <div>
        <h4>console.log</h4>
        <pre>
            {log.join("\n")}
        </pre>
    </div>
}

class Messages extends React.Component{
    remove(index) {
        console.log("inside remove", index);
        getStore().dispatch({type: "REMOVE_MESSAGE", index: index});
    }
    render() {
        var style = {
            error: "alert-danger",
            success: "alert-success"
        };
        
        var errorNodes = this.props.messages.map((msg, i) => {
            var close = this.remove.bind(this, i);
            return <div className={"alert "+style[msg.type]} role="alert" key={i} onClick={close}>
                <button type="button" className="close" aria-label="Close" onClick={close}>
                    <span aria-hidden="true">&times;</span>
                </button>
                {msg.msg}
            </div>
        });

        return (
            <div className="col-md-12">
                {errorNodes}
            </div>
        )
    }
}

export class TabBar extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            active: null
        }
    }
    setActive(key) {
        this.setState({active: key});
        if (this.props.setActive) {
            this.props.setActive(key);
        }
    }
    render() {
        let active = this.state.active || this.props.active || Object.keys(this.props.content)[0];

        let tabs = Object.keys(this.props.content).map((title, i) => {
            return <li role="presentation" key={i} className="nav-item">
                <a className={title === active ? "nav-link active" : "nav-link"}
                   style={linkStyle} onClick={() => this.setActive(title)}>
                    {title.replace("_"," ")}
                </a>
            </li>;
        });

        let content = this.props.content[active];

        return (
            <div>
                <ul className="nav nav-pills nav-fill">
                    {tabs}
                </ul>
                {content}
            </div>
        )
    }
}

class CurrencySelect extends React.Component{
    render() {
        let defaultValue = this.props.defaultValue;
        let setValue = this.props.setValue;
        let lsKey = "lastUsedCurrency";

        let currencies = ["SEK","CHF","HRK","MXN","ZAR","AUD","ILS","IDR","TRY","HKD","EUR","DKK","CAD","MYR","BGN",
            "NOK","RON","CZK","INR","CNY","THB","KRW","JPY","PLN","GBP","HUF","PHP","RUB","USD","SGD","NZD","BRL"];

        function doSet(value) {
            value = value.toUpperCase()
            if (currencies.includes(value)) {
                localStorage.setItem(lsKey, value);
            }
            setValue(value);
        }

        let currency = defaultValue || localStorage.getItem(lsKey) || "SEK";
        let currencySuggestions = currencies.map(c => ({display: c, value: c}));

        if (!defaultValue) {
            setValue(currency);
        }

        return (
            <div className="form-group">
                <label htmlFor="tokenInput">Currency: </label>
                <SimpleAutosuggest defaultValue={currency} suggestionCandidates={currencySuggestions} setValue={doSet}/>
            </div>
        )
    }
}

class SimpleAutosuggest extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            selectedSuggestions: [],
            value: this.props.defaultValue || "",
        };
    }
    setValue(e, {newValue, method}) {
        this.setState({value: newValue});
        this.props.setValue(newValue);
    }
    onSuggestionsFetchRequested(search) {
        this.setState({
            selectedSuggestions: this.getSuggestions(search)
        });
    }
    onSuggestionsClearRequested() {
        this.setState({
            selectedSuggestions: []
        });
    }
    getSuggestions(search) {
        return this.props.suggestionCandidates.filter(c => {
            const target = c.target || c.display;
            return _.every(search.value.toLowerCase().split(" "), s => target.toLowerCase().includes(s))
        });
    }
    renderSuggestion(suggestion) {
        return (
            <span>{suggestion.display}</span>
        );
    }
    getSuggestionValue(suggestion) {
        return suggestion.value;
    }
    render() {
        const inputProps = {
            className: 'form-control',
            placeholder: "",
            value: this.state.value,
            onChange: this.setValue.bind(this),
        };

        return (
            <Autosuggest
                focusFirstSuggestion={true}
                suggestions={this.state.selectedSuggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested.bind(this)}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested.bind(this)}
                renderSuggestion={this.renderSuggestion.bind(this)}
                getSuggestionValue={this.getSuggestionValue.bind(this)}
                inputProps={inputProps}/>
        )
    }
}

/**
 * Float input that emits a float, but still allows intermediate states like 1337. or 1337,
 */
export function FloatInput(props) {
    let value = props.value;
    let setValue = props.onChange;

    let [inputValue, setInputValue] = useState(""+value);

    function onChange(e) {
        let v = e.target.value;
        let floatV = parseFloat(v) || 0;
        if (floatV !== value) {
            setValue(floatV);
            setInputValue(""+floatV);
        } else {
            setInputValue(v);
        }
    }

    return <input {...props} value={inputValue} onChange={onChange}/>
}

export function Badge(props) {
    let member = props.member;
    let allMembers = props.allMembers || [member]; 

    function stringHash(str) {
        let hash = 0, i, chr;
        if (str.length === 0) return hash;
        for (i = 0; i < str.length; i++) {
            chr   = str.charCodeAt(i);
            hash  = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }

    let colors = ["#DF691A", "#5cb85c", "#5bc0de", "#f0ad4e", "#d9534f", "#FFA912", "#059078", "#2471EC"];
    let color = colors[Math.abs(stringHash(member.id)) % colors.length];

    return <span style={{
        // display: "block",
        // height: "20px",
        // width: "20px",
        // "line-height": "20px",
        // "font-size": "0.5em",

        "MozBorderRadius": "20px",
        "borderRadius": "20px",
        "padding": "4px",

        // "min-width": "100em",

        "backgroundColor": color,
        "color": "white",
        "textAlign": "center",
    }}>
        {uniqueShortName(allMembers, member.id)}
    </span>
};

export function shortName(fullName) {
    let nameWithoutParens = fullName.replace(/\([^)]*\)/g, "").trim();
    if (nameWithoutParens.includes(" ")) {
        return nameWithoutParens.split(" ").map(c => c[0]).slice(0,3).join("").toUpperCase();
    } else {
        return nameWithoutParens.charAt(0).toUpperCase() + fullName.slice(1,2);
    }
}

export function uniqueShortName(members, id) {
    // TODO move shortname to backend for consistency?
    let usedNames = new Set();
        console.log("Unique name called with", members);
    for (let m of members) {
        console.log(m, "name", m.name);
        let base = shortName(m.name)
        var finalName = base;
        var inc = 2;
        while (usedNames.has(finalName)) {
            finalName = base + inc;
            inc += 1;
        }
        usedNames.add(finalName);
        if (m.id === id) {
            return finalName;
        }
    }
    // id not found in members
    return "not_found";
}

export { Main, CurrencySelect };
