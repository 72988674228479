import React, {useEffect} from 'react';
import _ from 'lodash'
import {linkStyle} from "../common/common_components_util";
import {haxxEnabled} from "../common/utils";
import {getStore} from "../common/redux_store";
import {BrowserRouter, Link, Route, Switch} from "react-router-dom";
import {Register, Registered} from "./register_components";
import {SendReset, Reset} from "./reset_components";

export function Unauthed(props) {
    let connection = props.connection;

    return <BrowserRouter>
        <div>
            <Switch>
                <Route exact path="/register" render={(props) => <Register {...props}  connection={connection}/>}/>
                <Route exact path="/registered" render={(props) => <Registered {...props}/>}/>
                <Route exact path="/sendReset" render={(props) => <SendReset {...props}  connection={connection}/>}/>
                <Route exact path="/reset" render={(props) => <Reset {...props}/>}/>
                <Route render={(props) => <Connect {...props}  connection={connection}/>} />
            </Switch>
        </div>
    </BrowserRouter>
}

class Connect extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            host: (haxxEnabled() && localStorage.getItem("lastUsedHost")) || "wss://api.use.fish",
            phone: "46",
            publicName: "",
            deviceName: "",
            token: "",
        };
    }
    setHost(e) {
        localStorage.setItem("lastUsedHost", e.target.value);
        this.setState({host: e.target.value})
    }
    setEmail(e) {
        this.setState({email: e.target.value});
    }
    setPassword(e) {
        this.setState({password: e.target.value});
    }
    setToken(e) {
        this.setState({token: e.target.value});
    }
    emailLogin() {
        getStore().dispatch({
            type: "LOGIN_EMAIL",
            host: this.state.host,
            email: this.state.email,
            password: this.state.password
        });
    }
    connect() {
        this.connectWithToken(this.state.host, this.state.token)
    }
    connectWithToken(host, token) {
        getStore().dispatch({
            type: "CONNECT",
            host: host,
            token: token
        });
    }
    removeToken(key) {
        getStore().dispatch({
            type: "REMOVE_TOKEN",
            key: key
        });
    }
    render() {
        var previousTokens = JSON.parse(localStorage.getItem("cachedTokens")) || {};
        
        var originalContent = 
            <div className="col-md-12">
                {haxxEnabled() ?
                    <div className="mb-4">
                        <h3>Haxx connect</h3>

                        {_.map(previousTokens, (m, k) =>
                            <div key={k} className="link-color">
                                <button className="btn btn-sm btn-danger" onClick={this.removeToken.bind(this, k)}>X</button>
                                &nbsp; &nbsp;
                                <a style={linkStyle} onClick={this.connectWithToken.bind(this, m.host, m.token)}>{m.host} {m.email} {m.phone} ({m.name})</a>
                            </div>
                        )}

                        <div>
                            <div className="form-group">
                                <label htmlFor="tokenInput">Token: </label>
                                <input type="text" className="form-control" value={this.state.token} onChange={this.setToken.bind(this)}/>
                            </div>

                            <button className="btn btn-success" onClick={this.connect.bind(this)}>Connect with token</button>
                        </div>
                    </div>
                    : null}

                <h3>Fish Enterprises Login</h3>

                {haxxEnabled() ?
                    <div className="form-group">
                        <label htmlFor="backendHostInput">Backend host: </label>
                        <input type="text" className="form-control" value={this.state.host} onChange={this.setHost.bind(this)}/>
                    </div>
                    : null}

                <div className="mb-4">
                    <div className="form-group">
                        <label htmlFor="tokenInput">Email</label>
                        <input type="email" className="form-control" value={this.state.email} onChange={this.setEmail.bind(this)}/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="tokenInput">Password</label>
                        <input type="password" className="form-control" value={this.state.password} onChange={this.setPassword.bind(this)}/>
                    </div>

                    <button className="btn btn-default btn-success" onClick={this.emailLogin.bind(this)}>Login</button>
                </div>

                <div>
                    <Link to="/register">Register new account</Link>
                </div>
                <div>
                    <Link to="/sendReset">Reset Password</Link>
                </div>
            </div>;

        // {"ws://localhost:1337/realtime-46730974711":{"phone":"46730974711","name":"David Schlyter","token":"a032c24b78ce34ea2e018aeaa51c7632","host":"ws://localhost:1337/realtime"},
        // "wss://api.use.fish-46730974711":{"phone":"46730974711","name":"David Schlyter","token":"be02e569af5db61d7b348802fa294226","host":"wss://api.use.fish"}}

        return originalContent;
    }
}

export class LoggingIn extends React.Component {
    switchUser() {
        // This is a hacky way to reset
        localStorage.removeItem("connectionState");
        window.location.reload();
    }

    render() {
        return <div>
            <div className="text-right">
                <button className="btn btn-default" onClick={this.switchUser}>Logout</button>
            </div>

            <h2>You've got mail!</h2>

            <div>You should have received an email with a link to login. This link must be opened in this browser.</div>

            <div className="mt-3">To login to another acount, press logout to the top right.</div>
        </div>
    }
}

export function Connecting(props) {
    function switchUser() {
        getStore().dispatch({type: "LOG_OUT"});
    }

    return <div>
        <div className="text-right">
            <button className="btn btn-default" onClick={switchUser}>Logout</button>
        </div>

        <h3>Connecting...</h3>
    </div>
}

