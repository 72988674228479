import _ from 'lodash'

let haxxMode = window.location.href.indexOf("?nohaxx") === -1 &&
    (window.location.href.indexOf("?haxx") >= 0 || window.location.port === '3000');

function haxxEnabled() {
    return haxxMode;
}

let haxxLogState = {
    log: [],
    setState: null
}

function log(...args) {
    console.log(...args)
    haxxLog(...args)
}

function logError(...args) {
    console.error(...args)
    haxxLog("error: ", ...args)
}

function haxxLog(...args) {
    if (haxxEnabled() && haxxLogState.setState) {
        let msg = new Date().toISOString().substr(11,8) + " " + args.map(x => x.toString()).join(" ")
        haxxLogState.log.push(msg)
        haxxLogState.setState(haxxLogState.log)
    }
}

function registerLogSetState(setState) {
    haxxLogState.setState = setState;
}

export function getUserId(state) {
    return _.get(state, 'userState.publicUserId');
}

function randomHex(length) {
    return _.times(length,x => Math.random().toString(16).substring(7,8)).join("")
}

function unuglify(name) {
    // Beware beautiful code!
    return name.split(",").reverse().join(" ").split(" ").map(x => (x[0] ? x[0].toUpperCase() : "") + (x.slice ? x.slice(1).toLowerCase() : "")).join(" ");
}

function jsonTableStringify(obj) {
    obj = jsonTable(obj);
    return jsonTableString(obj)
}

function jsonTableString(jsonTable, indent=0) {
    let ind = " ".repeat(indent);
    let iind = " ".repeat(indent+2);

    if (_.isArray(jsonTable)) {
        if (jsonTable.length >= 1 && jsonTable[0] === "$$$jsonTable$$$") {
            let rows = jsonTable.slice(1).map(row => row.map(cell => ''+JSON.stringify(cell)));
            let maxLen = [];
            for (let col=0; col<rows[0].length; col++) {
                maxLen.push(rows.reduce((s, row) => Math.max(s, row[col].length), 0));
            }

            for (let col=0; col<rows[0].length; col++) {
                for (let row=0; row<rows.length; row++) {
                    rows[row][col] = rows[row][col].padEnd(maxLen[col]);
                }
            }

            let r = "[\n"+iind;
            r += JSON.stringify(jsonTable[0])+",\n"+iind;
            r += rows.map(row => "[" + row.join(", ") + "]").join(",\n"+iind);
            r += "\n"+ind+"]";
            return r;
        } else {
            let r = "[\n"+iind;
            r += _.map(jsonTable, (v) =>
                jsonTableString(v, indent+2)
            ).join(",\n"+iind);
            r += "\n"+ind+"]";
            return r;
        }
    } else if (_.isObject(jsonTable)) {
        let r = "{\n"+iind;
        r += _.map(jsonTable, (v, k) =>
            JSON.stringify(k)+": "+jsonTableString(v, indent+2)
        ).join(",\n"+iind);
        r += "\n"+ind+"}";
        return r;
    } else {
        return JSON.stringify(jsonTable);
    }
}

function jsonTable(obj) {
    if (_.isArray(obj)) {
        if (obj.length >= 2 && _.every(obj, x => !_.isArray(x) && _.isObject(x))) {
            let keys = new Set(obj.flatMap(_.keys));
            let ret = ["$$$jsonTable$$$"];

            let header = [];
            for (let k of keys) {
                header.push(k);
            }
            ret.push(header);

            for (let item of obj) {
                let line = [];
                for (let k of keys) {
                    line.push(item[k]);
                }
                ret.push(line);
            }

            return ret;
        }

        return obj.map(jsonTable);
    } else if (_.isObject(obj)) {
        return _.mapValues(obj, jsonTable)
    } else {
        return obj;
    }
}

function jsonTablify(obj, indent=0) {
    let ind = " ".repeat(indent);
    let iind = " ".repeat(indent+2);

    if (_.isArray(obj)) {
        if (obj.length >= 2 && _.every(obj, x => !_.isArray(x) && _.isObject(x))) {
            let keys = Array.from(new Set(obj.flatMap(_.keys)));

            let rows = [];
            for (let item of obj) {
                let row = [];
                for (let k of keys) {
                    row.push(''+JSON.stringify(item[k]));
                }
                rows.push(row);
            }

            let maxLen = [];
            for (let col=0; col<keys.length; col++) {
                maxLen.push(rows.reduce((s, row) => Math.max(s, row[col].length), 0));
            }

            let r = "[\n"+iind;
            r += rows.map(row => {
                let rr = "{";
                for (let col=0; col<keys.length; col++) {
                    rr += JSON.stringify(keys[col])+": "+row[col].padEnd(maxLen[col])+", ";
                }
                rr += "}";
                return rr;
            }).join(",\n"+iind);
            r += "\n"+ind+"]";
            return r;
        } else {
            let r = "[\n"+iind;
            r += _.map(obj, v => jsonTablify(v, indent+2))
                .join(",\n"+iind);
            r += "\n"+ind+"]";
            return r;
        }
    } else if (_.isObject(obj)) {
        let r = "{\n"+iind;
        r += _.map(obj, (v, k) => (JSON.stringify(k)+": "+jsonTablify(v, indent+2))).join(",\n"+iind);
        r += "\n"+ind+"}";
        return r;
    } else {
        return JSON.stringify(obj);
    }
}

export {haxxEnabled, log, logError, registerLogSetState, randomHex, unuglify, jsonTableStringify, jsonTablify}