import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

/*
Modal controller
Takes a ContentComponent, a ModalComponent, and subProps to send to both.
Gives both the toggle() prop to enable modal toggle.
 */

class ModalController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        };
        this.toggle = this.toggle.bind(this);
    }
    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }
    render() {
        let ContentComponent = this.props.contentComponent;
        let ModalComponent = this.props.modalComponent;
        let subProps = this.props.subProps || {};

        return (
            <div>
                <ContentComponent toggle={this.toggle} {...subProps}/>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>{this.props.modalTitle}</ModalHeader>
                    <ModalBody>
                        <ModalComponent toggle={this.toggle} {...subProps}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggle}>{this.props.cancelText || "Cancel"}</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default ModalController;
