import React from 'react';
import _ from 'lodash'
import {Link} from "react-router-dom";
import {ImageCreateStart} from "./bill_create_wizard";

export function groupPrefix(props) {
    let groupId = props.groupId || _.get(props, "match.params.groupId");

    if (groupId) {
        return "/groups/"+groupId;
    } else {
        return "";
    }
}

export function BillCreateStart(props) {
    return <div>
        <Link to={groupPrefix(props)+"/create"}>
            <button className="btn btn-success btn-lg btn-block"><span>🧾️</span> Add New Bill</button>
        </Link>
    </div>
}

export function BillCreate(props) {
    return <div>
        <h3>How do you want to create the bill?</h3>

        <ImageCreateStart {...props}/>
        <Link to={groupPrefix(props) + "/create/quick/one"}>
            <button className="btn btn-success btn-lg btn-block mb-2"><span>🏎️</span> Split Amount Equally</button>
        </Link>
        <Link to={groupPrefix(props) + "/create/quick/items"}>
            <button className="btn btn-info btn-lg btn-block mb-2"><span>🥧</span> Manually Enter Multiple Items</button>
        </Link>
    </div>
}

export function AddMembers(props) {
    let members = props.members;
    let setMembers = props.setMembers;
    let allMembers = props.allMembers;
    let title = props.title !== undefined ? props.title : "Members";

    function add(m, e) {
        setMembers(members.concat([{id: m.id, name: m.name}]));
        e.preventDefault();
    }

    function addAll(e) {
        for (let m of allMembers) {
            if (members.filter(am => am.id === m.id).length === 0) {
                members = members.concat([{id: m.id, name: m.name}]);
            }
            setMembers(members);
        }
        e.preventDefault();
    }

    function remove(m, e) {
        setMembers(members.filter(em => em.id !== m.id));
        e.preventDefault();
    }

    function removeAll(e) {
        setMembers([]);
        e.preventDefault();
    }

    return <div>

        <table className="table table-striped">
            <tbody>
                <tr> { /* Dummy row to not make the first title row striped */ } </tr>
                <tr>
                    <td>
                        <h3>{title}</h3>
                    </td>
                    <td>
                        { members.length < allMembers.length ?
                            allMembers.length > 0 ?
                                <button className="btn btn-success float-right" onClick={e => addAll(e)}>Add all!</button>
                                : null
                            : <button className="btn btn-danger float-right" onClick={e => removeAll(e)}>Remove all!</button>
                        }
                    </td>
                </tr>
            {allMembers.map((m) =>
                <tr key={m.id}>
                    <td>
                        <strong>{m.name}</strong>
                    </td>
                    <td>
                        {members.filter(am => am.id === m.id).length === 0 ?
                            <button className="btn btn-success float-right" onClick={e => add(m, e)}>Add!</button>
                            : <button className="btn btn-danger float-right" onClick={e => remove(m, e)}>Remove!</button>
                        }
                    </td>
                </tr>
            )}
            </tbody>
        </table>
    </div>

}
